export class AiCameraManageUserFace {
  userId: string;
  userName: string;
  userRole: string;
  state: string;
  registeredImageUrls: string;
  isActive: boolean;
  issuedDate: any;
}
export class UserFaceID {
  id: number;
  accountId: number;
  name: string;
  dateCreated: number;
  dateUpdated: number;
  status: boolean;
  roleId: number;
  data: string;
}
export class AIRole {
  id: number;
  code: string;
  detail: string;
}

export class ScenarioModel {
  id: number;
  name: string;
  numberCamera: number;
  dateCreated: string;
  dateUpdated: string;
  code: string;
  ScenarioGroup: ScenarioGroup;
}

export class ScenarioGroup {
  id: number;
  dateCreated: string;
  dateUpdated: string;
  code: string;
  name: string;
}

export class AICameraModel {
  aiStatus: string;
  cameraId: number;
  cameraName: string;
  cloudId: string;
  connectionStatus: string;
  isActiveNotification: string;
  _isActive: boolean; // Internal use only
}
export class CameraGroup {
  bunchId: number;
  bunchName: string;
  cameras: AICameraModel[];
}

export class TimeViewModel {
  id: number = 0;
  timeName: string = "";
  dayName: string = "";
}

export class IntrusionScenarioCameraModel {
  image: string;
  scenarioCamera: ScenarioCameraModel;
  rois: PolygonModel[];
  weekTimeIntrusionKeeping: WeekTimeIntrusionModel;
}
export class ScenarioCameraModel {
  id: number;
  cameraId: number;
  cameraName: string;
  cloudId: string;
  scenarioId: number;
  scenarioName: string;
  dateCreated: number;
  ownerId: number;
  connectionStatus: string;
}

export class PolygonModel {
  roiId?: number;
  // The polygon points format: [x1, y1, x2, y2, x3, y3, ...]
  value: number[];
}

/**
 * WeekTimeIntrusionModel
 * @key {string} Weekday: mon, tue, wed, thur, fri, sat, sun
 * @value {object[]} Array of { id?: number; first: number; last: number; }
 * @value.first {number} The first time of the week in milliseconds
 * @value.last {number} The last time of the week in milliseconds
 *
 */
export class WeekTimeIntrusionModel {
  [weekDay: string]: { id?: number; first: number; last: number }[];
}

/**
 * TimeRepeatModel
 * @firstTime {string} The start time of the day in format 'HH:mm:ss'
 * @lastTime {string} The end time of the day in format 'HH:mm:ss'
 *@selected {string[]} Array of selected weekdays in format 'mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun'
 */
export class TimeRepeatModel {
  firstTime: string;
  lastTime: string;
  selected: string[];
}

export class faceIdModel {
  cameraId: number;
  cameraName: string;
  userId: number;
  imageUrl: string;
  imageResult: string;
  userName: string;
  timeStart: number;
  employeeCode: string;
  accountId: number;
  dateCreated: number;
  dateUpdated: number;
  roleId: number;
  checkin: checkInModel;
  checkout: checkOutModel;
}

export class IntrusionEventModel {
  cameraId: number;
  cameraName: string;
  userId: number;
  imageResult: string;
  timeStart: number;
}

export class EliteGuestEventModel extends IntrusionEventModel {
  imageUrl: string;
  userName: string;
}

export class SmartAlertModel {
  id: number;
  username: string;
  dateCreated: string;
  status: "READ" | "UNREAD";
  detail: string;
  title: string;
  path: string;
  cameraName: string;
}

export class eventFaceModel {
  userName: string;
  cameraName: string;
  typeCheck: string;
  employeeCode: string;
  lateOrSoon: string;
  time: number;
  imageResult: string;
}
export class eventFaceIdModel {
  data: faceIdModel[];
  page: number;
  limit: number;
  totalElements: number;
  totalPages: number;
}
export class checkInModel {
  imageResult: string;
  time: number;
  late: string;
  lateTime: number;
}
export class checkOutModel {
  imageResult: string;
  time: number;
  soon: string;
  soonTime: number;
}
export class DayModel {
  hFirst: number = 0;
  mFirst: number = 0;
  sFirst: number = 0;
  hLast: number = 23;
  mLast: number = 59;
  sLast: number = 59;
  first: number = 0;
  last: number = 0;
  id: number = 0;
}
export class ConfigTimeModel {
  mon: DayModel;
  tue: DayModel;
  wed: DayModel;
  thur: DayModel;
  fri: DayModel;
  sat: DayModel;
  sun: DayModel;
}

export class LogModel {
  id: number = 0;
  name: string = "";
  username: string = "";
  dateCreated: number = 0;
  status: string = "";
  type: string = "";
  typeDisplay: string = "";
}
export class LogCountingModel {
  id: number = 0;
  timestamp: number = 0;
  count: number = 0;
  cameraId: number = 0;
  cameraName:string="camera name";
  cloudId:string="IDhi";
}
export class LogCountingModels {
  cameraId: number = 0;
  cameraName: string = "camera";
  cloudID:string = "IDhi";
  countingModels: LogCountingModel[] = [];
}
export class CameraCoungtingModel {
  scenarioCamera: ScenarioCameraModel;
  rois: [];
  weekTimeIntrusionKeeping: number | null;
  image: string;
  id: number;
}
export class datasetModel {
  cameraId:string = "";
  label: string = "Camera Name";
  data: { x: string; y: number; }[];
  borderColor: string = "#ee0033";
  backgroundColor: string = "transparent";
  borderWidth: number = 1;
  pointRadius: number = 1.3;
  pointBackgroundColor: string = "#ee0033";
  lineTension: number = 0.4;
}
