import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { CameraModel, RecordModel } from "../../_models/index";
declare var $: any;
declare var videojs: any;
declare var document: any;

@Component({
  selector: "app-videojs",
  templateUrl: "./videojs.component.html",
  styleUrls: ["./videojs.component.css"],
})
export class VideojsComponent implements OnInit, AfterViewInit {
  @ViewChild("videoElement") public videoElement: ElementRef;
  @Input() record: RecordModel;
  @Input() camera: CameraModel;
  @Input() type: string;
  loaded: boolean = false;
  isInit: boolean = false;
  player: any;
  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    if (this.type === "LIVE_VIEW") {
      this.initPlayer();
    }
  }

  initPlayer() {
    this.loaded = true;
    this.isInit = true;
    const autoplay = this.type === "LIVE_VIEW" ? true : false;
    let src =
      this.type === "LIVE_VIEW" ? this.camera.streamUrl : this.record.url;
    let typeVideo = "application/x-mpegURL";
    // src = 'http://download.cloudcam.vn/NAS36-F3/record/IDhiFU9132402/IDhiFU9132402_1737674205647_1737675109646.mp4';
    // src='https://s3download.cloudcam.vn/15days/record/calongbien_admin1682492354143/calongbien_admin1682492354143_1737576147014_1737577048918.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20250123T074247Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Credential=cloudcamera%2F20250123%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=d8cb56cb0e545e349d162d2cbcf8c6890ed4212bcf96207ac46f1ddd6409a6fe';
    // let techOrder = this.type === 'LIVE_VIEW' ? 'flash' : 'html5';
    const controls = this.type === "LIVE_VIEW" ? false : true;
    // src='https://s3-north1.viettelidc.com.vn/1days/record/demoidc1711135801154/demoidc1711135801154_1711677045384_1711677946498.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240329T031140Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=4d4095afa68656f25b4a%2F20240329%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=0479544f3e5e6cbf2f4a7cb3eb62c55479930bedc03972ddbc0904246eadd839';
    // if(src.includes('.mp4'))

    if (this.type === "LIVE_VIEW") {
      if(this.camera.codec === "H265"){
      let l2 = src.length - 13;
      src = src.substring(0, l2) + "_sfm4s_playlist.m3u8";
      }
      this.player = videojs(`videojs-flash-player-${this.camera.id}`, {
        techOrder: ["html5"],
        autoplay: autoplay,
        controls: true,
        isAudio: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          progressControl: false,
          currentTimeDisplay: false,
          remainingTimeDisplay: false,
          pictureInPictureToggle: false,
        },
        preload: "auto",
        fluid: true,
        html5: {
          vhs: {
            overrideNative: true, // Ghi đè native playback
          },
          hls: {
            withCredentials: true, // Thiết lập nếu cần gửi thông tin xác thực
          },
        },
        sources: [{ src: src, type: typeVideo }],
      });
    } else {
      if (this.camera?.codec === "H265") {
        typeVideo = "video/mp4";
        src = src.replace("https://", "http://");
        this.player = videojs(`videojs-flash-player-${this.record.id}`, {
          techOrder: ["html5"],
          autoplay: autoplay,
          controls: true,
          preload: "auto",
          fluid: true,
          html5: {
            vhs: {
              overrideNative: true, // Ghi đè native playback
            },
            hls: {
              withCredentials: true, // Thiết lập nếu cần gửi thông tin xác thực
            },
          },
          sources: [{ src: src, type: typeVideo }],
        });
      } else {
        this.player = videojs(`videojs-flash-player-${this.record.id}`, {
          techOrder: ["html5"],
          autoplay: autoplay,
          controls: true,
          preload: "auto",
          fluid: true,
          html5: {
            vhs: {
              overrideNative: true, // Ghi đè native playback
            },
            hls: {
              withCredentials: true, // Thiết lập nếu cần gửi thông tin xác thực
            },
          },
          sources: [{ src: src, type: typeVideo }],
        });
      }
    }

    this.player.play();
  }
  close() {
    this.isInit = false;
    this.player.dispose();
  }
  takeSnapshot() {
    const canvasElement = <HTMLCanvasElement>document.createElement("CANVAS");
    const objVideo = document.getElementById(
      this.videoElement.nativeElement.id
    );
    const context = canvasElement.getContext("2d");
    canvasElement.width = 1280;
    canvasElement.height = 768;
    context.fillRect(0, 0, 1280, 768);
    context.drawImage(objVideo, 0, 0, 1280, 768);
    const link = document.createElement("a");
    link.setAttribute("download", "Snapshot.png");
    const dataURL = canvasElement.toDataURL();
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
  }
  z: any = 1;
  zoomInVideo() {
    if (this.z < 3.0) {
      this.z += 0.1;
    }
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.z,
    });
  }
  zoomOutVideo() {
    if (this.z > 0.3) {
      this.z -= 0.1;
    }
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.z,
    });
  }

  forward = () => {
    this.skip(15);
  };

  backward = () => {
    this.skip(-15);
  };

  skip = (time) => {
    let currTime = this.player.currentTime();
    this.player.currentTime(currTime + time);
  };
}
