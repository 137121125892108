import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CameraModel, SelectDate } from "src/app/_models";
import { datasetModel, LogCountingModel } from "src/app/_models/ai-camera";
import { AiCameraEventService } from "src/app/_services/ai-camera-event.service";
import { CameraService } from "src/app/_services";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AiCameraCountingService } from "src/app/_services/ai-camera-counting.service";
declare var moment: any;
declare var $: any;

@Component({
  selector: "app-report-counting-events",
  templateUrl: "./report-counting-events.component.html",
  styleUrls: ["./report-counting-events.component.css"],
})
export class ReportCountingEventsComponent implements OnInit, AfterViewInit {
  @ViewChild("selectCamera") selectCamera!: ElementRef;
  logCountings: LogCountingModel[] = [];
  listCamera: {cameraId: number, cameraName: string}[];
  fullLogChart: LogCountingModel[] = [];
  pageSizes = [5, 10, 20, 50, 100, 1000];
  pageSize = 10;
  cameraIds: number[] = [];
  currentPage: number = 1;
  totalPage: number = 1;
  totalItems: number = 0;
  keySearch: string = "";
  startTime: Date = new Date(moment().startOf("day").toDate());
  endTime: Date = new Date();
  startDate: SelectDate = new SelectDate();
  endDate: SelectDate = new SelectDate();
  hours = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));

  constructor(
    private _logService: AiCameraEventService,
        private _countingService: AiCameraCountingService
  ) {}

  ngOnInit(): void {
    this._countingService.getAllCameraAI(41,0, 10000, '').subscribe(
      (res) => {
        let listSceCamera = res.data;
        this.listCamera = listSceCamera.map(({ scenarioCamera }) => ({
          cameraId: scenarioCamera.cameraId,
          cameraName: scenarioCamera.cameraName
        }));
      },
      (error) => {
        console.log(error);
      }
    );
    //load lib datetime picker
    if ($(".select-time").length) {
      $(".select-time").select2({
        minimumResultsForSearch: -1,
      });
    }

    
  }
  ngAfterViewInit() {
    const selectElem = $(this.selectCamera.nativeElement);
    selectElem.select2({
      placeholder: "Chọn camera thống kê",
      allowClear: true,
    });

    // Lưu danh sách trước khi thay đổi
    let previousValues: string[] = [];
    selectElem.on("change", (event: any) => {
      let values = selectElem.val();
      if (values.length > 5) {
        // Xác định giá trị mới vừa được thêm vào
        let newAddedValue = values.find((v) => !previousValues.includes(v));
        // Hiển thị cảnh báo
        Swal.fire({
          icon: "warning",
          title: "Giới hạn camera",
          text: "Chọn tối đa 5 camera để đảm bảo hiệu suất hiển thị tốt nhất.",
          confirmButtonText: "OK",
        });
        // Loại bỏ giá trị mới nhất (giá trị thứ 6)
        if (newAddedValue) {
          values = values.filter((v) => v !== newAddedValue);
          selectElem.val(values).trigger("change"); // Cập nhật lại select2
        }
      }
      // Cập nhật dữ liệu
      this.cameraIds = values;
    });
    
    setTimeout(() => {
      if ($("#date-time-picker5").length) {
        $("#date-time-picker5")
          .dateRangePicker({
            inline: true,
            container: "#date-time-picker-container5",
            alwaysOpen: true,
          })
          .bind("datepicker-first-date-selected", function (event, obj) {
            if (obj.date1) {
              $("#select-date-first").val(moment(obj.date1).format("DD/MM/YYYY"));
            } else {
              $("#select-date-first").val(moment());
            }
          })
          .bind("datepicker-change", function (event, obj) {
            if (obj.date2) {
              $("#select-date-second").val(
                moment(obj.date2).format("DD/MM/YYYY")
              );
            } else {
              $("#select-date-second").val(
                moment(new Date()).startOf("day").format("DD/MM/YYYY")
              );
            }
          });
      }
    }, 0);
  }
  findAllLogs() {
    let startTimeSecond = this.startTime.getTime() / 1000;
    let endTimeSecond = Math.round(this.endTime.getTime() / 1000);
    this.getFullLog();
    this._logService
      .findAllAICountLogs(
        this.cameraIds,
        startTimeSecond,
        endTimeSecond,
        this.currentPage,
        this.pageSize
      )
      .subscribe(
        (res) => {
          this.logCountings = res.content;
          // this.pageSize = res.size;
          // this.currentPage = res.number;
          this.totalItems = res.totalElements;
        },
        (error) => {
          console.log("Lỗi lấy detail log AI Counting " + error);
        }
      );
  }
  getFullLog() {
    //Đưa time về s
    let startTimeSecond = this.startTime.getTime() / 1000;
    let endTimeSecond = Math.round(this.endTime.getTime() / 1000);
    this._logService
      .getAllChartLog(this.cameraIds, startTimeSecond, endTimeSecond)
      .subscribe(
        (res) => {
          this.fullLogChart = res.map(({ timestamp, count, cameraId,cameraName,cloudId }) => ({
            timestamp, count, cameraId,cameraName,cloudId
          }));
          this.fullLogChart = this.fullLogChart.sort(
            (a, b) => a.timestamp - b.timestamp
          );
          console.log(this.fullLogChart);
        },
        (error) => {
          console.log("Lỗi load danh sách log AI Counting: " + error);
        }
      );
  }
  //#region Hàm hỗ trợ
  onPageChange(event: any) {
    this.currentPage = event;
    this.findAllLogs();
  }
  onLimitChange() {
    this.currentPage = 1;
    this.findAllLogs();
  }
  displayTime(time: number) {
    return moment(Number(time) * 1000).format("DD/MM/YYYY HH:mm");
  }
  convertAMPM(_date: SelectDate) {
    if (_date.ampm == "PM") {
      if (_date.hours < 12) _date.hours = Number(_date.hours) + 12;
      if (_date.hours == 12) _date.hours = Number(_date.hours) - 12;
    }
  }
  selectDate() {
    $("#selectDate").modal("hide");
    this.startDate.fulldate = $("#select-date-first").val().toString();
    this.startDate.hours = Number($("#select-hours-first").val());
    this.startDate.minutes = $("#select-minutes-first").val().toString();
    this.startDate.ampm = $("#select-ampm-first").val().toString();
    this.convertAMPM(this.startDate);
    let fStartTime =
      this.startDate.fulldate +
      " " +
      this.startDate.hours +
      ":" +
      this.startDate.minutes +
      ":00";
    if (this.startDate.fulldate != "")
      this.startTime = new Date(moment(fStartTime, "DD-MM-YYYY HH:mm:ss"));
    this.endDate.fulldate = $("#select-date-second").val().toString();
    this.endDate.hours = Number($("#select-hours-second").val());
    this.endDate.minutes = $("#select-minutes-second").val().toString();
    this.endDate.ampm = $("#select-ampm-second").val().toString();
    this.convertAMPM(this.endDate);
    let fEndTime =
      this.endDate.fulldate +
      " " +
      this.endDate.hours +
      ":" +
      this.endDate.minutes +
      ":00";
    if (this.endDate.fulldate != "")
      this.endTime = new Date(moment(fEndTime, "DD-MM-YYYY HH:mm:ss"));
  }
  //#endregion
  filterData() {
    if(this.cameraIds.length >0){
      let diffInHours = this.endTime.getTime() - this.startTime.getTime();
      if (diffInHours >= 3600000 && diffInHours <= 82800000) {
        this.findAllLogs();
        $("#selectDate").modal("hide");
      } else {
        Swal.fire(
          "Thông báo",
          "Khoảng thời gian không hợp lệ: phải lớn hơn 1 giờ và nhỏ hơn 23 giờ!",
          "info"
        );
      }
    }
    else{Swal.fire(
      "Thông báo",
      "Chưa chọn camera cần thống kê",
      "info"
    );
    }
  }
  exportToExcel(){
    
    // Chuyển đổi danh sách thành worksheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.fullLogChart);

    // Căn chỉnh độ rộng của cột
    const columnWidths = [
      { wch: 25 }, // Cột timestamp
      { wch: 10 }, // Cột count
      { wch: 10 }, // Cột cameraId
      { wch: 25 }, // Cột cameraName
      { wch: 20 }, // Cột cloudId
    ];
    worksheet['!cols'] = columnWidths;

    // Tạo workbook và thêm worksheet vào
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Danh sách': worksheet },
      SheetNames: ['Danh sách'],
    };

    // Xuất file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Lưu file
    saveAs(data, 'DanhSach.xlsx');
  }
}
