<div class="container-fluid p-2">
    <div class="page-title">
      Đăng ký ảnh đếm người trong khung hình
    </div>
    <div class="page-action" style="margin: 1.5rem 0 0 0;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex">
            <div class="search-form event-search mr-3">
              <label for="search-camera" class="sr-only">Nhập từ khóa tìm kiếm</label>
              <input type="text" class="form-control border-control" id="search-camera"
                placeholder="Nhập từ khóa tìm kiếm" [(ngModel)]="searchTxt" (ngModelChange)="onSearchChange($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 10px 0px 0px 10px;font-weight: bold;">
        <p>Đếm người trong khung hình (<span style="color: #ee0033;">{{ totalItem }} camera</span>)</p>
      </div>
    </div>
    <div class="page-table">
      <div class="container">
        <div class="table-responsive">
          <table class="table table-striped user-table">
            <thead>
              <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                <th scope="col">STT</th>
                <th scope="col">Tên Camera</th>
                <th scope="col">Cloud ID</th>
                <th scope="col">Trạng thái</th>
                <!-- <th scope="col" class="text-center">Thời gian</th> -->
                <th scope="col">Thao tác</th>
                <!-- <th scope="col" class="text-center">Hình ảnh</th> -->
              </tr>
            </thead>
            <tbody *ngIf="loading">
              <tr>
                <td colspan="7" class="text-center">
                  <i class="fa fa-spinner fa-pulse"></i>
                  <span>Đang tải dữ liệu...</span>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!loading">
              <tr
                *ngFor="let item of cameraCoungtings | paginate: { itemsPerPage: pageSize || 10, currentPage: currentPage, totalItems: totalItem};  let index=index;">
                <td>{{ index + 1 + pageSize * (currentPage-1) }}</td>
                <td>{{item.scenarioCamera.cameraName}}</td>
                <td>{{item.scenarioCamera.cloudId}}</td>
                <td>
                  <div *ngIf="item.scenarioCamera.connectionStatus=='CONNECTED'">
                    <span class="camera-status active"></span>{{'ad_connect'|translate}}
                  </div>
                  <div *ngIf="item.scenarioCamera.connectionStatus=='DISCONNECTED'">
                    <span class="camera-status inactive"></span>{{'ad_no_connect'|translate}}
                  </div>
                </td>
                <!-- <td class="text-center text-truncate" style="max-width: 200px;" title="{{displayRepeats(item)}}">
                  {{displayRepeats(item)}}
                </td> -->
                <td>
                  <nav class="action-list text-center">
                    <a href="javascript:;" title="Chỉnh sửa" (click)="showInfo()">
                      <i class="icon icon-edit"></i>
                    </a>
                    <!-- <a href="javascript:;" title="Chỉnh sửa" routerLink="/aicamera/register-areas/{{item.id}}">
                      <i class="icon icon-edit"></i>
                    </a> -->
                  </nav>
                </td>
                <!-- <td class="text-center" style="cursor: zoom-in;" (click)="openModal(item)" data-toggle="modal" data-target="#zoomInModal">
                  <polygon-image *ngIf="item.image" [imageSrc]="item.image" [width]="160" [polygons]="getPolygons(item)"></polygon-image>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row no-gutters">
          <div class="col-6 text-left" style="display: flex;align-items: center;">
            <label>Số hàng hiển thị mỗi trang</label>
            <select class="border-control" [(ngModel)]="pageSize"
              style="padding: 0.375rem 1rem; margin-left: 0.5rem; cursor: pointer;" (change)="onLimitChange()">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
          <div class="col-6 text-right">
            <div class="text-right">
              <nav class="nav-pagination">
                <pagination-template #p="paginationApi" (pageChange)="onPageChange($event)">
                  <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                    <li class="page-item" [class.disabled]="p.isFirstPage()">
                      <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                        <i class="icon icon-arrow-left"></i></a>
                    </li>
  
                    <li class="page-item" [class.active]="p.getCurrent()===page.value" *ngFor="let page of p.pages">
                      <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                    </li>
  
                    <li class="page-item" [class.disabled]="p.isLastPage()">
                      <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                        <i class="icon icon-arrow-right"></i></a>
                    </li>
                  </ul>
                </pagination-template>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <div class="modal fade" id="zoomInModal" tabindex="-1" role="dialog" aria-labelledby="zoomInModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title">Khu vực cảnh báo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <i class="icon icon-close"></i>
          </button>
        </div>
        <div style="width: 100%;">
          <polygon-image *ngIf="previewItem" [imageSrc]="previewItem.image" [width]="500" [polygons]="getPolygons(previewItem)" [lineWidth]="2"></polygon-image>
        </div>
      </div>
    </div>
  </div>
   -->