<div class="landing position-relative">
    <header class="landing-header">
      <nav class="navbar navbar-expand-xl navbar-dark p-0"> 
        <div class="container-fluid">
          <div class="row no-gutters w-100">
            <div class="col-12 col-xl-2">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#idcMenu" aria-controls="idcMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="brand-logo">
                <a href="#">
                  <img class="logo" src="assets/landing_logo.svg" alt="Cloud Camera"/>
                  <h2>{{'ldp_service_of_idc'|translate}}</h2>
                </a>
              </div>
            </div>
            <div class="col-12 col-xl-10 clearfix">
              <div class="collapse navbar-collapse" id="idcMenu">
                <ul>
                  <li class="active">
                    <a href="#sectionBanner">
                      TRANG CHỦ
                    </a>
                  </li>
                  <li>
                    <a href="#sectionFeatures">
                      TÍNH NĂNG
                    </a>
                  </li>
                  <li>
                    <a href="#sectionDownload">
                      TẢI XUỐNG
                    </a>
                  </li>
                  <li>
                    <a href="#sectionSolutions">
                      GIẢI PHÁP
                    </a>
                  </li>
                  <li>
                    <a href="#sectionManual">
                      HƯỚNG DẪN
                    </a>
                  </li>
                  <li>
                    <a href="#sectionSupport">
                      LIÊN HỆ
                    </a>
                  </li>
                </ul>
                <div class="user-menu">
                  <button class="btn btn-primary btn-auth">Đăng nhập</button>
                  <button class="btn btn-warning btn-auth">Đăng ký</button>
                  <div class="dropdown dropdown-language">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img class="mr-1" src="assets/landing/icons/vietnam.svg" alt="Vietnamese"/>
                      VN
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguage">
                      <a class="dropdown-item" href="#">Tiếng Việt</a>
                      <a class="dropdown-item" href="#">English</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </nav>
    </header>
    <div class="page-container">
      <section class="contact-wrapper">
        <div class="contact-wrapper-background">
          <div class="contact-wrapper-background-thumbnail"
               style="background-image: url(assets/landing/contact-wrapper.jpg)"></div>
        </div>
  
        <div class="container">
          <div class="row">
            <div class="col-12">
              <form action="#" class="contact-form">
                <h2 class="contact-form-title">Hãy để chúng tôi liên hệ bạn</h2>
                <h3 class="contact-form-sub-title">Vui lòng điền vào mẫu bên dưới, Viettel Cloud Camera sẽ liên hệ với bạn trong thời gian sớm
                  nhất có thể
                </h3>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="fullNameForm">Họ và Tên</label>
                      <input type="text" class="form-control" id="fullNameForm" placeholder="Họ và Tên">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="officeForm">Chức vụ</label>
                      <input type="text" class="form-control" id="officeForm" placeholder="Chức vụ">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="companyForm">Công ty</label>
                      <input type="text" class="form-control" id="companyForm" placeholder="Công ty">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="phoneNumberForm">Số điện thoại</label>
                      <input type="text" class="form-control" id="phoneNumberForm" placeholder="Họ và Tên">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="emailForm">Địa chỉ Email</label>
                      <input type="email" class="form-control" id="emailForm" placeholder="Địa chỉ Email">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="countryForm">Quốc gia</label>
                      <input type="text" class="form-control" id="countryForm" placeholder="Quốc gia">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="addressForm">Địa chỉ</label>
                      <input type="text" class="form-control" id="addressForm" placeholder="Địa chỉ">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="topicForm">Tiêu đề</label>
                      <input type="text" class="form-control" id="topicForm" placeholder="Tiêu đề">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label">Nội dung</label>
                      <textarea class="form-control" rows="6" placeholder="Nội dung" required></textarea>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="input-file">
                          <label for="fileInput" class="file-label blue">
                            <i class="icon icon-ic-attach-file" aria-hidden="true"></i>
                            File đính kèm
                          </label>
                          <input id="fileInput" class="file-input" type="file" name="file" multiple/>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="file-list">
                          <div class="file-item">
                            <p class="image-name pr-4">
                              <i class="icon icon-single-folded-content"></i> image_01.jpg
                              <a href="#" class="image-cancel"><i class="icon icon-cancel"></i></a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Mã xác thực</label>
                          <input type="text" class="form-control" placeholder="Mã xác thực"/>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label d-none d-md-block">&nbsp;</label>
                        <div class="captcha-group">
                          <span class="verification-code"><img src="../assets/captcha-code.jpg" alt=""></span>
                          <a class="captcha-reload ml-2" href="#" title="Reload"><i class="icon icon-refresh"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-2">
                    <button class="btn-submit" type="submit">Gửi</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="brand-logo">
              <a href="#">
                <img class="logo" src="assets/landing_logo.svg" alt="Cloud Camera"/>
              </a>
            </div>
            <ul class="contact-info-list">
              <li class="contact-info address">
                <p>Tầng 16 Center Building Hapulico<br/>
                  Số 01 Nguyễn Huy Tưởng, quận Thanh Xuân, Hà Nội</p>
              </li>
              <li class="contact-info mobile">
                <p>(+84) 4 6272 8866 *** Fax: (+84) 4 6269 2129</p>
              </li>
              <li class="contact-info email">
                <p><a href="mailto:support@viettelidc.com.vn">support@viettelidc.com.vn</a></p>
              </li>
            </ul>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <div class="footer-block">
                  <h5>Công ty</h5>
                  <ul class="footer-list">
                    <li>
                      <a href="#">Viettel IDC</a>
                    </li>
                    <li>
                      <a href="#">Khách hàng</a>
                    </li>
                    <li>
                      <a href="#">Điều khoản</a>
                    </li>
                    <li>
                      <a href="#">Liên hệ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="footer-block">
                  <h5>Dịch vụ liên quan</h5>
                  <ul class="footer-list">
                    <li>
                      <a href="#">Dịch vụ 1</a>
                    </li>
                    <li>
                      <a href="#">Dịch vụ 2</a>
                    </li>
                    <li>
                      <a href="#">Dịch vụ 3</a>
                    </li>
                    <li>
                      <a href="#">Dịch vụ 4</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="footer-block">
                  <h5>Hỗ trợ và giải pháp</h5>
                  <ul class="footer-list">
                    <li>
                      <a href="#">Hỗ trợ</a>
                    </li>
                    <li>
                      <a href="#">Hướng dẫn mua hàng</a>
                    </li>
                    <li>
                      <a href="#">Câu hỏi thường gặp</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="footer-block">
                  <h5>Tải ứng dụng</h5>
                  <a href="#" target="_blank" class="download-btn d-block"><img src="assets/landing/btn-apple.png"
                                                                                alt="App Store"></a>
                  <a href="#" target="_blank" class="download-btn d-block"><img src="assets/landing/btn-google.png"
                                                                                alt="Google Play Store"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p>Copyright © 2015 - 2025, Công ty TNHH Viettel - CHT</p>
        </div>
      </div>
    </footer>
    <a href="#" id="backToTop"></a>
  </div>