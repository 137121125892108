import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AicameraComponent } from "./aicamera.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { RegisterFaceIDComponent } from "./register-face-id/register-face-id.component";
import { CheckInEventComponent } from "./manage-event/check-in-event/check-in-event.component";
import { RegisterAreasComponent } from './register-areas/register-areas.component';
import { RegisterAreaComponent } from './register-areas/register-area/register-area.component';
import { RegisterCameraComponent } from './register-camera/register-camera.component';
import { RegisterForScenarioComponent } from './register-camera/register-for-scenario/register-for-scenario.component';
import { CameraForScenarioComponent } from './register-camera/register-for-scenario/camera-for-scenario/camera-for-scenario.component';
import { ManageLogComponent } from './manage-log/manage-log/manage-log.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from 'src/app/_pipes/pipe.module';
import { AicameraRoutingModule } from './aicamera-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { IntrusionEventComponent } from './manage-event/intrusion-event/intrusion-event.component';
import { EliteGuestEventComponent } from './manage-event/elite-guest-event/elite-guest-event.component';
import { ConfigAlertComponent } from './smart-alert/config-alert/config-alert.component';
import { SmartAlertComponent } from './smart-alert/smart-alert-event/smart-alert.component';
import { ConfigCameraListComponent } from './smart-alert/config-alert/config-camera-list/config-camera-list.component';
import { RegisterCountingComponent } from './register-counting/register-counting.component';
import { CountingEventsComponent } from './manage-event/counting-events/counting-events.component';
import { ReportCountingEventsComponent } from './manage-event/report-counting-events/report-counting-events.component';

@NgModule({
  declarations: [
    AicameraComponent,
    SidebarComponent,
    RegisterFaceIDComponent,
    CheckInEventComponent,
    RegisterAreasComponent,
    RegisterAreaComponent,
    RegisterCameraComponent,
    RegisterCountingComponent,
    RegisterForScenarioComponent,
    CameraForScenarioComponent,
    ManageLogComponent,
    IntrusionEventComponent,
    SmartAlertComponent,
    EliteGuestEventComponent,
    ConfigAlertComponent,
    ConfigCameraListComponent,
    RegisterCountingComponent,
    CountingEventsComponent,
    ReportCountingEventsComponent
  ],
  imports: [
    AicameraRoutingModule,
    CommonModule,
    NgxPaginationModule,
    NgxPageScrollModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    PipeModule
  ]
})
export class AicameraModule { }
