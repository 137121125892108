import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ApiUrlService {
  readonly aiCameraManagementUrl = "http://171.244.53.25:8081/api/v1/";
  readonly BASE_URL = "https://api.cloudcam.vn/cloud-camera/services/";
  // readonly AI_BASE_URL = "http://27.71.24.102:8133/cloud-camera/microservices/v1/ai-module/";
  readonly AI_BASE_URL = "https://api-ai.cloudcam.vn/cloud-camera/microservices/v1/ai-module/";
  // readonly BASE_URL = "http://27.71.24.100:8330/cloud-camera/services/";
  readonly ACCOUNT_LOGIN = `${this.BASE_URL}account/login-web`;
  readonly ACCOUNT_LOGOUT = `${this.BASE_URL}account/logout`;
  readonly ACCOUNT_FIND_ALL1 = `${this.BASE_URL}account/find-all-account`;
  readonly ACCOUNT_CHANGE_PASSWORD = `${this.BASE_URL}account/change-password`;
  readonly ACCOUNT_REFRESH_TOKEN = `${this.BASE_URL}account/refresh-token`;
  readonly ACCOUNT_FORGOT_PASSWORD = `${this.BASE_URL}account/forgot-password`;
  readonly ACCOUNT_VERIFY_RESET_PASSWORD = `${this.BASE_URL}account/verify-reset-password-token`;
  readonly ACCOUNT_RESET_PASSWORD = `${this.BASE_URL}account/reset-password`;
  readonly ACCOUNT_REGISTER = `${this.BASE_URL}account/register`;
  readonly ACCOUNT_FIND_ALL = `${this.BASE_URL}account/find-sub`;
  readonly ACCOUNT_REGISTER_SUB = `${this.BASE_URL}account/register-sub`;
  readonly ACCOUNT_UPDATE_SUB = `${this.BASE_URL}account/update-sub`;
  readonly ACCOUNT_REMOVE = `${this.BASE_URL}account/remove`;
  readonly ACCOUNT_BALANCE = `${this.BASE_URL}account/balance`;
  readonly ACCOUNT_DETAIL = `${this.BASE_URL}account/detail`;
  readonly Account_find_by_id = `${this.BASE_URL}account/find-by-id`;
  readonly Account_update = `${this.BASE_URL}account/update`;
  readonly Account_list_camera_allow_assign = `${this.BASE_URL}management/list-camera-allow-assign`;
  readonly Account_list_camera_assigned = `${this.BASE_URL}management/list-camera-assigned`;
  readonly Account_management_assign = `${this.BASE_URL}management/assign`;
  readonly Account_transaction_history = `${this.BASE_URL}account/transaction-history`;
  readonly Account_active = `${this.BASE_URL}account/active`;
  readonly STREAM_LIVE_RTC = `${this.BASE_URL}stream/liveWebrtc`;
  readonly STREAM_LIVE = `${this.BASE_URL}stream/live-for-web`;
  // readonly STREAM_LIVE = `${this.BASE_URL}stream/live`;
  readonly STREAM_PLAYBACK = `${this.BASE_URL}stream/playback`;
  readonly STREAM_DOWNLOAD_RECORD = `${this.BASE_URL}stream/download-record`;
  readonly STREAM_SEARCH_RECORD = `${this.BASE_URL}stream/search-record`;

  readonly CAMERA_FIND_ALL = `${this.BASE_URL}management/find-all-camera`;
  readonly CAMERA_REMOVE = `${this.BASE_URL}management/remove-camera`;
  readonly CAMERA_ADD = `${this.BASE_URL}management/add-camera-viettel`;
  readonly CAMERA_ADD_THIRD_PARTY = `${this.BASE_URL}management/add-camera-third-party`;
  readonly CAMERA_UPDATE = `${this.BASE_URL}management/update-camera-viettel`;
  readonly CAMERA_UPDATE_THIRD_PARTY = `${this.BASE_URL}management/update-camera-third-party`;
  readonly CAMERA_FILTER = `${this.BASE_URL}model/find-all`;
  readonly CAMERA_CHANGE_STREAM = `${this.BASE_URL}stream/change-streaming-protocol`;
  readonly CAMERA_detail_camera = `${this.BASE_URL}management/detail-camera`;
  readonly CAMERA_ptz_update = `${this.BASE_URL}ptz/update`;
  readonly CAMERA_EXPORT_EXCEL = `${this.BASE_URL}management/excel`;

  readonly BUNCH_FIND_ALL = `${this.BASE_URL}bunch/find-all`;
  readonly BUNCH_REMOVE = `${this.BASE_URL}bunch/remove`;
  readonly BUNCH_CREATE = `${this.BASE_URL}bunch/create`;
  readonly BUNCH_UPDATE = `${this.BASE_URL}bunch/update`;
  readonly Bunch_add_camera = `${this.BASE_URL}bunch/add-camera`;
  readonly Bunch_remove_camera = `${this.BASE_URL}bunch/remove-camera`;
  readonly MANAGEMENT_BUNCH_CAMERA_TREEVIEW = `${this.BASE_URL}management/list-camera-tree-view`;
  readonly MANAGEMENT_LIST_CAMERA_BY_BUNCH_ID = `${this.BASE_URL}management/list-camera-by-bunch-id`;
  readonly Management_find_camera_no_bunch = `${this.BASE_URL}management/find-camera-no-bunch`;
  readonly Payment_by_bank_card = `${this.BASE_URL}payment/by-bank-card`;
  readonly Package_find_all = `${this.BASE_URL}package/find-all`;
  readonly Package_tree_view_not_buy_package = `${this.BASE_URL}management/tree-view-not-buy-package`;
  readonly Package_tree_view_already_bought_package = `${this.BASE_URL}management/tree-view-already-bought-package`;
  readonly Package_find_camera_not_buy_package_by_bunch_id = `${this.BASE_URL}management/find-camera-not-buy-package-by-bunch-id`;
  readonly Package_find_camera_already_bought_package_by_bunch_id = `${this.BASE_URL}management/find-camera-already-bought-package-by-bunch-id`;
  readonly Package_find_camera_not_buy_package = `${this.BASE_URL}management/find-camera-not-buy-package`;
  readonly Package_find_camera_already_bought_package = `${this.BASE_URL}management/find-camera-already-bought-package`;
  readonly Package_add_third_party_camera_price = `${this.BASE_URL}package/add-third-party-camera-price`;
  readonly Package_remove_camera = `${this.BASE_URL}package/remove-camera`;
  readonly Package_buy = `${this.BASE_URL}package/buy`;
  readonly stream_search_record = "stream/search-record";
  readonly management_update_camera_third_party =
    "management/update-camera-third-party";
  readonly management_find_camera_allow_share_by_bunch_id =
    "management/find-camera-allow-share-by-bunch-id";
  readonly management_tree_view_allow_share_camera =
    "management/tree-view-allow-share-camera";
  readonly management_find_all_camera = "management/find-all-camera";
  readonly management_update_camera_viettel =
    "management/update-camera-viettel";
  readonly account_find_sub = "account/find-sub";
  readonly account_list_role = "account/list-role";
  readonly account_list_status = "account/list-status";
  readonly account_change_status = "account/change-status";
  readonly payment_by_mobile_card = "payment/by-mobile-card";
  readonly account_balance = "account/balance";
  readonly account_notification = "account/notification";
  readonly account_count_unread_notification =
    "account/count-unread-notification";
  readonly application_check_update = "application/check-update";
  readonly ptz_move = "ptz/move";
  readonly account_update_fcm_client_token = "account/update-fcm-client-token";
  readonly gen_authenticator = `${this.BASE_URL}account/gen-authenticator`;
  readonly check_totp = `${this.BASE_URL}account/check-otpcode`;
  readonly gen_qrCode = `${this.BASE_URL}account/gen-qrcode`;
  readonly do_twofactor = `${this.BASE_URL}account/do-twofactor`;
  readonly pass_twofactor = `${this.BASE_URL}account/pass-twofactor`;
  readonly write_secretKey = `${this.BASE_URL}account/write-secretkey`;
  readonly write_qrcode = `${this.BASE_URL}account/write-qrcode`;
  readonly change_protocol = `${this.BASE_URL}management/change-live-protocol`;
  //---list api dashboard
  readonly statistic_disconnect = `${this.BASE_URL}dashboard/realtime`;
  readonly chart_disconnect = `${this.BASE_URL}dashboard/by-time`;
  readonly chart_infor = `${this.BASE_URL}dashboard/info`;
  readonly top10_disconnect = `${this.BASE_URL}dashboard/top-camera-disconnected`;
  readonly detail_camera_disconnect = `${this.BASE_URL}dashboard/detail-camera`;
  readonly infoMetaCam = `${this.BASE_URL}dashboard/get-metadata-camera`;
  readonly find_all_disconnect_cam = `${this.BASE_URL}dashboard/find-all-cam-dis`;
  readonly getListUserCamera = `${this.BASE_URL}account/account-assign-camera`;
  readonly getListNotifiWeb = `${this.BASE_URL}notification/notify-maintenance`;
  readonly stopNotifi = `${this.BASE_URL}notification/stop-notice`;
  //Copy permission
  readonly Account_camera = `${this.BASE_URL}account/account-assign-camera`;
  readonly Owner_camera = `${this.BASE_URL}management/owner-camera`;
  readonly Remove_List_camera = `${this.BASE_URL}management/remove-list-camera`;
  readonly Remove_List_By_Username=`${this.BASE_URL}management/remove-camera-by-username`;

  // AI - Camera
  readonly AI_CAMERA_LOGIN =
    "http://171.244.53.25:8081/api/v1/authentication/administrator-authorize";
  readonly AI_CAMERA_EVENT_GET_EVENTS =
    "http://171.244.53.25:8081/api/v1/event/get-events";
  readonly AI_CAMERA_EVENT_GET_USERS =
    "http://171.244.53.25:8081/api/v1/operation/get-user";
  readonly GET_USER = `${this.aiCameraManagementUrl}operation/get-user`;
  readonly LOGIN_ADMIN = `${this.aiCameraManagementUrl}authentication/administrator-authorize`;
  readonly ENROLL_USER = `${this.aiCameraManagementUrl}operation/enroll-user`;
  readonly UPDATE_USER = `${this.aiCameraManagementUrl}operation/update-user`;
  readonly DELETE_USER = `${this.aiCameraManagementUrl}operation/delete-user`;
  readonly AI_STATISTIC_CAMERA = `${this.AI_BASE_URL}scenario/get-all`;
  readonly AI_GET_ALL_CAMERA = `${this.AI_BASE_URL}scenario/get-all-camera-ai`;
  //New AI Cloud Camera
  readonly AI_CHECKPLUGIN = `${this.AI_BASE_URL}register-ai-scenario/check`;
  readonly FACEID_FINDALL = `${this.AI_BASE_URL}register-ai-scenario/get-face-by-account`;
  readonly FACEID_SEARCH = `${this.AI_BASE_URL}register-ai-scenario/find-face-by-keyword`;
  readonly FACEID_GET_BY_ID = ``;
  readonly FACEID_ADD_NEW = `${this.AI_BASE_URL}register-ai-scenario/add-face`;
  readonly FACEID_LISTROLE = `${this.AI_BASE_URL}register-ai-scenario/get-role-face`;
  readonly FACEID_UPDATE = `${this.AI_BASE_URL}register-ai-scenario/update-face`;
  readonly FACEID_UPDATE_STATUS = `${this.AI_BASE_URL}register-ai-scenario/update-face-status`;
  readonly INTRUSION_UPDATE = `${this.AI_BASE_URL}register-ai-scenario/update-intrusion`;
  readonly FIND_INTRUSION_SCENARIO_CAMERA = `${this.AI_BASE_URL}intrusion-endpoint/find-intrusion-scenario-camera`;
  readonly FIND_INTRUSION_SCENARIO_CAMERA_BY_ID = `${this.AI_BASE_URL}intrusion-endpoint/find-scenario-camera-by-id`;
  readonly UPDATE_INTRUSION_SCENARIO_CAMERA = `${this.AI_BASE_URL}intrusion-endpoint/update-intrusion-ai`;
  readonly INTRUSION_UPDATE_BY_ID = `${this.AI_BASE_URL}register-ai-scenario/get-intrusion-by-id`;
  readonly STATISTIC_CAMERA_AI = `${this.AI_BASE_URL}register-ai-scenario/statistic-camera-already-assigned`;
  readonly GET_ALL_CAM_AI = `${this.AI_BASE_URL}register-ai-scenario/get-all-camera-already-assigned`;
  readonly GET_ALL_BUNCH_AI = `${this.AI_BASE_URL}register-ai-scenario/get-all-bunch`;
  readonly GET_CAM_AI_BY_BUNCH = `${this.AI_BASE_URL}register-ai-scenario/get-camera-by-bunch-id`;
  readonly GET_CAM_AI_NO_BUNCH = `${this.AI_BASE_URL}register-ai-scenario/get-camera-no-bunch`;
  readonly GET_ALL_SCENARIO_GROUP = `${this.AI_BASE_URL}register-ai-scenario/get-all-scenario-group`;
  readonly GET_ALL_SCENARIO = `${this.AI_BASE_URL}register-ai-scenario/get-all-scenario`;
  readonly GET_TREE_VIEW_CAMERAS = `${this.AI_BASE_URL}config-notification/tree-view`;
  readonly ACTIVATE_ALERT_CAMERA = `${this.AI_BASE_URL}config-notification/active`;
  readonly DEACTIVATE_ALERT_CAMERA = `${this.AI_BASE_URL}config-notification/inactive`;
  readonly GET_SCENARIO_BY_ID = `${this.AI_BASE_URL}register-ai-scenario/find-scenario-by-id`;
  readonly ACTIVE_CAM_AI = `${this.AI_BASE_URL}register-ai-scenario/active-face-id-ai`;
  readonly INACTIVE_CAM_AI = `${this.AI_BASE_URL}register-ai-scenario/inactive-face-id-ai`;
  readonly ACTIVE_INTRUSION_AI = `${this.AI_BASE_URL}register-ai-scenario/active-intrusion-ai`;
  readonly INACTIVE_INTRUSION_AI = `${this.AI_BASE_URL}register-ai-scenario/inactive-intrusion-ai`;
  readonly EVENT_FACEID = `${this.AI_BASE_URL}event/get-event-face-id`;
  readonly EVENT_CHECKINOUT = `${this.AI_BASE_URL}event/checkin-checkout`;
  readonly STATISTIC_TIME = `${this.AI_BASE_URL}event/statistic-timekeeping`;
  readonly GET_ALL_LOG = `${this.AI_BASE_URL}log/find-log`;
  readonly GET_INTRUSION_EVENTS = `${this.AI_BASE_URL}intrusion-endpoint/find-event-intrusion`;
  readonly GET_ELITE_GUEST_EVENTS = `${this.AI_BASE_URL}event/get-event-vip-customer`;
  readonly GET_SMART_ALERTS = `${this.AI_BASE_URL}log/find-notification`;
  readonly MARK_ALERT_AS_READ = `${this.AI_BASE_URL}log/mark-notifications-as-read`;
  //Config time đi sớm về muộn
  readonly REGISTER_TIMEKEEPING = `${this.AI_BASE_URL}timekeeping/register-timekeeping`;
  readonly DELETE_TIMEKEEPING = `${this.AI_BASE_URL}timekeeping/delete-timekeeping`;
  readonly FIND_TIMEKEEPING = `${this.AI_BASE_URL}timekeeping/find-timekeeping`;
  // Firebase
  readonly FIREBASE_SUBSCRIBE_TOPIC = `${this.AI_BASE_URL}firebase/subscribe`;
  readonly FIREBASE_UNSUBSCRIBE_TOPIC = `${this.AI_BASE_URL}firebase/unsubscribe`;
  //AI people counting
  readonly AI_ALL_CAMERA_COUNTING=`${this.AI_BASE_URL}counting-endpoint/find-counting-scenario-camera`;
  readonly ACTIVE_COUNTING_AI=`${this.AI_BASE_URL}register-ai-scenario/active-people-counting-ai`;
  readonly INACTIVE_COUNTING_AI = `${this.AI_BASE_URL}register-ai-scenario/inactive-counting-ai`;
  readonly AI_UPDATE_COUNTING=`${this.AI_BASE_URL}counting-endpoint/update-people-counting-ai`;
  readonly AI_LOGS_COUNTING=`${this.AI_BASE_URL}counting-endpoint/logs`;
  readonly AI_DETAIL_CAMERA=`${this.AI_BASE_URL}counting-endpoint/find-scenario-camera-by-id`;
  readonly AI_CHART_LOG = `${this.AI_BASE_URL}counting-endpoint/statistic-line`;
}
