import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CameraModel, SelectDate } from "src/app/_models";
import { datasetModel, LogCountingModel } from "src/app/_models/ai-camera";
import { AiCameraEventService } from "src/app/_services/ai-camera-event.service";
import Chart from "chart.js";
import { CameraService } from "src/app/_services";
import Swal from "sweetalert2";
import { AiCameraCountingService } from "src/app/_services/ai-camera-counting.service";
declare var moment: any;
declare var $: any;

@Component({
  selector: "app-counting-events",
  templateUrl: "./counting-events.component.html",
  styleUrls: ["./counting-events.component.css"],
})
export class CountingEventsComponent implements OnInit, AfterViewInit {
  @ViewChild("smoothLineChart") chartCanvas!: ElementRef;
  @ViewChild("chartLegend") chartLegend!: ElementRef;
  @ViewChild("selectCamera") selectCamera!: ElementRef;
  myChart: any;
  logCountings: LogCountingModel[] = [];
  logDrawChart: LogCountingModel[] = [];
  datasets: datasetModel[];
  listCamera: {cameraId: number, cameraName: string}[];
  pageSizes = [5, 10, 20, 50, 100, 1000];
  pageSize = 5;
  currCameraName = "";
  cameraIds: number[] = [];
  cameraNames: string[] = [];
  currCameraId:number = 0;
  currentPage: number = 1;
  totalPage: number = 1;
  totalItems: number = 0;
  keySearch: string = "";
  startTime: Date = new Date(moment().startOf("day").toDate());
  endTime: Date = new Date();
  startDate: SelectDate = new SelectDate();
  endDate: SelectDate = new SelectDate();
  hours = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));

  constructor(
    private _logService: AiCameraEventService,
    private _countingService: AiCameraCountingService
  ) {}
  ngOnInit(): void {
    this._countingService.getAllCameraAI(41,0, 10000, '').subscribe(
      (res) => {
        let listSceCamera = res.data;
        this.listCamera = listSceCamera.map(({ scenarioCamera }) => ({
          cameraId: scenarioCamera.cameraId,
          cameraName: scenarioCamera.cameraName
        }));
      },
      (error) => {
        console.log(error);
      }
    );
    //load lib datetime picker
    if ($(".select-time").length) {
      $(".select-time").select2({
        minimumResultsForSearch: -1,
      });
    }

  }
  ngAfterViewInit() {
    const selectElem = $(this.selectCamera.nativeElement);
    selectElem.select2({
      placeholder: "Chọn camera thống kê",
      allowClear: true,
    });

    // Lưu danh sách trước khi thay đổi
    let previousValues: string[] = [];
    let cameraColorMap: { [id: string]: string } = {}; // Map để lưu màu cho từng camera
    let availableColors = [...this.colors]; // Danh sách màu có thể sử dụng

    selectElem.on("change", (event: any) => {
      let selectedData = selectElem.select2("data"); // Lấy cả value và text
      let values = selectElem.val();
      let names = values.map((id) => {
        let item = selectedData.find((data: any) => data.id === id);
        return item ? item.text : "";
      });
      if (values.length > 5) {
        // Xác định giá trị mới vừa được thêm vào
        let newAddedValue = values.find((v) => !previousValues.includes(v));
        // Hiển thị cảnh báo
        Swal.fire({
          icon: "warning",
          title: "Giới hạn camera",
          text: "Chọn tối đa 5 camera để đảm bảo hiệu suất hiển thị tốt nhất.",
          confirmButtonText: "OK",
        });

        // Loại bỏ giá trị mới nhất (giá trị thứ 6)
        if (newAddedValue) {
          values = values.filter((v) => v !== newAddedValue); // Loại bỏ giá trị mới thêm vào
          names = names.filter((_, index) =>
            values.includes(selectedData[index].id)
          );
          selectElem.val(values).trigger("change"); // Cập nhật lại select2
        }
      }

      // Xử lý gán màu cố định cho mỗi camera
      let updatedCameraColorMap: { [id: string]: string } = {};
      values.forEach((id) => {
        if (!cameraColorMap[id]) {
          // Nếu camera chưa có màu, lấy màu từ danh sách availableColors
          cameraColorMap[id] = availableColors.shift() || "#000000"; // Nếu hết màu thì mặc định là đen
        }
        updatedCameraColorMap[id] = cameraColorMap[id]; // Cập nhật danh sách mới
      });

      // Trả lại màu của camera bị xóa vào danh sách availableColors
      Object.keys(cameraColorMap).forEach((id) => {
        if (!values.includes(id)) {
          availableColors.push(cameraColorMap[id]); // Trả lại màu vào danh sách có thể dùng
        }
      });

      // Cập nhật lại danh sách màu của camera
      cameraColorMap = updatedCameraColorMap;

      // Cập nhật danh sách trước khi thay đổi
      previousValues = [...values];

      // Cập nhật dữ liệu
      this.cameraIds = values;
      this.datasetLabels = values.map((id, index) => ({
        cameraId: values[index],
        cameraName: names[index].trim(), // Xóa khoảng trắng thừa nếu có
        color: cameraColorMap[id], // Lấy màu từ map
      }));

      console.log(this.datasetLabels);
      this.getLogDrawChart();
    });
    setTimeout(() => {
      if ($("#date-time-picker5").length) {
        $("#date-time-picker5")
          .dateRangePicker({
            inline: true,
            container: "#date-time-picker-container5",
            alwaysOpen: true,
          })
          .bind("datepicker-first-date-selected", function (event, obj) {
            if (obj.date1) {
              $("#select-date-first").val(moment(obj.date1).format("DD/MM/YYYY"));
            } else {
              $("#select-date-first").val(moment());
            }
          })
          .bind("datepicker-change", function (event, obj) {
            if (obj.date2) {
              $("#select-date-second").val(
                moment(obj.date2).format("DD/MM/YYYY")
              );
            } else {
              $("#select-date-second").val(
                moment(new Date()).startOf("day").format("DD/MM/YYYY")
              );
            }
          });
      }
    }, 0);
  }
  ngOnDestroy() {
    if ($("#date-time-picker5").data("dateRangePicker")) {
      $("#date-time-picker5").data("dateRangePicker").destroy();
    }
  }
  //#region get data from API
  getLogDrawChart() {
    //Đưa time về s
    let startTimeSecond = this.startTime.getTime() / 1000;
    let endTimeSecond = Math.round(this.endTime.getTime() / 1000);
    this._logService
      .getAllChartLog(this.cameraIds, startTimeSecond, endTimeSecond)
      .subscribe(
        (res) => {
          this.logDrawChart = res;
          this.logDrawChart = this.logDrawChart.sort(
            (a, b) => a.timestamp - b.timestamp
          );
          const roundedStartTime = this.roundToHour(startTimeSecond, "floor"); // Làm tròn xuống
          const roundedEndTime = this.roundToHour(endTimeSecond, "ceil"); // Làm tròn lên
          const groupedLogs = this.groupByCameraId(this.logDrawChart);
          //duyệt tạo dữ liệu datasets
          this.datasets = [];
          let indexMap = 0;
          for (const [cameraId, logs] of Object.entries(groupedLogs)) {
            const objDataset = new datasetModel();
            objDataset.cameraId = cameraId;
            let objMap = this.datasetLabels.find(o=>o.cameraId ===cameraId);
            objDataset.label = objMap.cameraName;
            objDataset.borderColor = objDataset.pointBackgroundColor = objMap.color;
            indexMap++;
            //Lọc log trùng ngày giờ
            const filteredLogs = Object.values(
              logs.reduce((acc, log) => {
                const key = this.displayShortTime(log.timestamp); // Lấy key theo giờ:phút:ngày
                if (!acc[key] || log.count > acc[key].count) {
                  acc[key] = log; // Chỉ giữ log có count lớn nhất
                }
                return acc;
              }, {} as Record<string, { timestamp: number; count: number }>)
            );
            // Sắp xếp danh sách theo timestamp tăng dần
            objDataset.data = filteredLogs
              .sort((a, b) => a.timestamp - b.timestamp)
              .map((log) => ({
                x: this.displayTime(log.timestamp),
                y: log.count,
              }));
            //push điểm đầu và điểm cuối
            objDataset.data.unshift({
              x: this.displayTime(roundedStartTime),
              y: 0,
            });
            objDataset.data.push({
              x: this.displayTime(roundedEndTime),
              y: objDataset?.data[objDataset.data.length - 1].y ?? 0,
            });
            this.datasets.push(objDataset);
          }

          //vẽ biểu đồ khi đã load xong data
          if (this.chartCanvas) {
            this.createSmoothLineChart(roundedStartTime, roundedEndTime);
          } else {
            console.error("chartCanvas chưa được gán giá trị!");
          }
        },
        (error) => {
          console.log("Lỗi load danh sách log AI Counting: " + error);
        }
      );
  }
  getLogDetail(cameraId) {
    this.currCameraId = cameraId;
    let startTimeSecond = this.startTime.getTime() / 1000;
    let endTimeSecond = Math.round(this.endTime.getTime() / 1000);
    let cameraIds = [cameraId];
    this._logService
      .findAllAICountLogs(
        cameraIds,
        startTimeSecond,
        endTimeSecond,
        this.currentPage,
        this.pageSize
      )
      .subscribe(
        (res) => {
          this.logCountings = res.content;
          // this.pageSize = res.size;
          // this.currentPage = res.number;
          this.totalItems = res.totalElements;
        },
        (error) => {
          console.log("Lỗi lấy detail log AI Counting " + error);
        }
      );
  }
  //#endregion
  //#region Hàm hỗ trợ
   onPageChange(event: any) {
    this.currentPage = event;
    this.getLogDetail(this.currCameraId);
  }
  onLimitChange() {
    this.currentPage = 1;
    this.getLogDetail(this.currCameraId);
  }
  groupByCameraId(
    logs: LogCountingModel[]
  ): Record<number, LogCountingModel[]> {
    return logs.reduce((acc, log) => {
      if (!acc[log.cameraId]) {
        acc[log.cameraId] = [];
      }
      acc[log.cameraId].push(log);
      return acc;
    }, {} as Record<number, LogCountingModel[]>);
  }
  datasetLabels = [];
  cameraColorMap = [
    { name: "camName", color: "#8979FF" },
    { name: "camName", color: "#FF928A" },
    { name: "camName", color: "#3CC3DF" },
    { name: "camName", color: "#FFAE4C" },
    { name: "camName", color: "#537FF1" },
  ];
  colors = ["#8979FF", "#FF928A", "#3CC3DF", "#FFAE4C", "#537FF1"];
  displayShortTime(time: number) {
    return moment(Number(time) * 1000).format("HH:mm");
  }
  displayTime(time: number) {
    return moment(Number(time) * 1000).format("DD/MM/YYYY HH:mm");
  }
  roundToHour(
    timestamp: number,
    roundType: "floor" | "ceil" = "floor"
  ): number {
    const msPerHour = 60 * 60; // 1 giờ tính bằng giây
    return roundType === "floor"
      ? Math.floor(timestamp / msPerHour) * msPerHour
      : Math.ceil(timestamp / msPerHour) * msPerHour;
  }
  convertAMPM(_date: SelectDate) {
    if (_date.ampm == "PM") {
      if (_date.hours < 12) _date.hours = Number(_date.hours) + 12;
      if (_date.hours == 12) _date.hours = Number(_date.hours) - 12;
    }
  }
  selectDate() {
    this.startDate.fulldate = $("#select-date-first").val().toString();
    this.startDate.hours = Number($("#select-hours-first").val());
    this.startDate.minutes = $("#select-minutes-first").val().toString();
    this.startDate.ampm = $("#select-ampm-first").val().toString();
    this.convertAMPM(this.startDate);
    let fStartTime =
      this.startDate.fulldate +
      " " +
      this.startDate.hours +
      ":" +
      this.startDate.minutes +
      ":00";
    if (this.startDate.fulldate != "")
      this.startTime = new Date(moment(fStartTime, "DD-MM-YYYY HH:mm:ss"));
    this.endDate.fulldate = $("#select-date-second").val().toString();
    this.endDate.hours = Number($("#select-hours-second").val());
    this.endDate.minutes = $("#select-minutes-second").val().toString();
    this.endDate.ampm = $("#select-ampm-second").val().toString();
    this.convertAMPM(this.endDate);
    let fEndTime =
      this.endDate.fulldate +
      " " +
      this.endDate.hours +
      ":" +
      this.endDate.minutes +
      ":00";
    if (this.endDate.fulldate != "")
      this.endTime = new Date(moment(fEndTime, "DD-MM-YYYY HH:mm:ss"));
  }
  //#endregion
  //#region Hàm vẽ biểu đồ
  createCustomLegend(): string {
    return `<div style="display: flex; justify-content: center; align-items: center; gap: 20px; flex-wrap: nowrap;">
        ${this.datasetLabels
          .map(
            (dataset) => `
          <div style="display: flex; align-items: center;">
            <span style="display: flex; align-items: center;">
              <span style="display: inline-block; width: 20px; height: 2px; background-color: ${dataset.color}; margin-right: 5px;"></span>
              <span style="display: inline-block; width: 8px; height: 8px; background-color: ${dataset.color}; border-radius: 50%;"></span>
              <span style="display: inline-block; width: 20px; height: 2px; background-color: ${dataset.color}; margin-left: 5px;"></span>
            </span>
            <span style="color: #777; font-size: 14px; margin-left: 10px;">${dataset.cameraName}</span>
          </div>
        `
          )
          .join("")}
      </div>`;
  }
  createSmoothLineChart(startTime, endTime) {
    const ctx = this.chartCanvas?.nativeElement?.getContext("2d");
    const start = moment.unix(startTime);
    const end = moment.unix(endTime);
    const totalMinutes = end.diff(start, "minutes");
    let step;
    if (totalMinutes <= 180) {
      step = 15; // Mốc 15 phút
    } else if (totalMinutes <= 720) {
      step = 30; // Mốc 30 phút
    } else {
      step = 60; // Mốc 1 giờ
    }
    if (this.myChart) {
      this.myChart.destroy(); // Hủy biểu đồ cũ trước khi tạo mới
    }

    this.myChart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: this.datasets,
      },
      options: {
        legend: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                parser: "DD-MM-YYYY HH:mm",
                tooltipFormat: "DD-MM-YYYY HH:mm",
                unit: step === 60 ? "hour" : "minute",
                stepSize: step === 60 ? 1 : step,
                displayFormats: {
                  minute: "HH:mm",
                  hour: "HH:mm",
                },
              },
              ticks: {
                autoSkip: false,
                min: this.displayTime(startTime),
                max: this.displayTime(endTime),
                // min: this.displayShortTime(startTime),
                // max: this.displayShortTime(endTime),
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true, // Bắt đầu từ 0
                min: 0, // Đảm bảo giá trị tối thiểu là 0
              },
            },
          ],
        },
        hover: {
          mode: "nearest", // Chỉ hover vào điểm hoặc line
          intersect: true, // Chỉ khi thực sự chạm vào thì mới hover
        },
        onHover: (event, elements) => {
          if (elements.length > 0) {
            event.target.style.cursor = "pointer"; // Đổi con trỏ chuột thành pointer
          } else {
            event.target.style.cursor = "default";
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const chart = event.chart;
            const datasetIndex = elements[0]._datasetIndex; // Lấy index của dataset được click
            const dataset = this.myChart.data.datasets[datasetIndex]; // Lấy dataset tương ứng
            const cameraID = dataset.cameraId; // Giả sử mỗi dataset có chứa cameraID
            this.currCameraName = dataset.label;
            if (cameraID) {
              this.getLogDetail(cameraID);
            } else {
              alert("Không tìm thấy Camera ID");
            }
          }
        },
      },
    });
    this.myChart.update();
    this.chartLegend.nativeElement.innerHTML = this.createCustomLegend();
  }
  drawChartFromTimeRange() {
    this.selectDate();
    let diffInHours = this.endTime.getTime() - this.startTime.getTime();
    if (diffInHours >= 3600000 && diffInHours <= 86500000) {
      // Kiểm tra khoảng cách
      this.getLogDrawChart();
      $("#selectDate").modal("hide");
    } else {
      Swal.fire(
        "Thông báo",
        "Khoảng thời gian không hợp lệ: phải lớn hơn 1 giờ và nhỏ hơn 24 giờ!",
        "info"
      );
    }
  }
  //#endregion
}
