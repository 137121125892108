import { Injectable } from "@angular/core";
import { Http, URLSearchParams } from "@angular/http";
import { CookieService } from "ngx-cookie-service";
import { BaseService } from "./base.service";
import { ApiUrlService } from "./api-url.service";

@Injectable({
  providedIn: "root",
})
export class AiCameraCountingService extends BaseService {
  constructor(
    public _http: Http,
    public _apiUrl: ApiUrlService,
    public cookie: CookieService
  ) {
    super(_http, _apiUrl, cookie);
  }
  getAllCameraAI(
    _scenarioId: number,
    _page: number,
    _limit: number,
    _keyword: string
  ) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("scenarioId", `${_scenarioId}`);
    params.set("page", `${_page}`);
    params.set("limit", `${_limit}`);
    params.set("keyword", `${_keyword}`);
    return this._http
      .get(
        this._apiUrlService.AI_ALL_CAMERA_COUNTING,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
}
