import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AiCameraEventService } from "src/app/_services/ai-camera-event.service";
import { ScenarioModel } from "src/app/_models/ai-camera";
import Swal from "sweetalert2";
import { switchMap } from "rxjs/operators";
declare var $: any;

@Component({
  selector: "app-register-for-scenario",
  templateUrl: "./register-for-scenario.component.html",
  styleUrls: ["./register-for-scenario.component.css"],
})
export class RegisterForScenarioComponent implements OnInit {
  pageSize: number = 5;
  pageSizes = [5, 10, 20, 50, 100, 1000];
  txtSearch: string = "";
  currentPage: number = 0;
  loading: boolean = false;
  lstGrpCam: GroupCamera[] = [];
  lstLoadCamAI: number[] = [];
  scenario: ScenarioModel;
  scenarioId: number;
  constructor(
    private _aiService: AiCameraEventService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.scenarioId = parseInt(params["id"]);
      this.getScenarioById(this.scenarioId);
    });
  }

  exeMultiAPI() {
    this._aiService
      .getAllBunchAI()
      .pipe(
        switchMap((res) => {
          this.lstGrpCam = res;
          let noBunch = new GroupCamera();
          noBunch.id = 0;
          noBunch.name = "Chưa phân nhóm";
          this.lstGrpCam.push(noBunch);
          return this._aiService.getAllCamAI(this.scenario.id);
        })
      )
      .subscribe(
        (res1) => {
          this.lstLoadCamAI = res1.cameraIds;
          //Duyệt danh sách nhóm và checked camera đã đăng ký AI
          this.lstGrpCam.forEach((grp) => {
            if (grp.id == 0) {
              this._aiService.getAllCamNoBunch().subscribe(
                (res2) => {
                  let lstIDRes = res2.map((cam) => cam.id);
                  this.lstLoadCamAI.forEach((id) => {
                    $("#cam_" + id).prop("checked", true);
                  });
                  let allExist = lstIDRes.every((id) =>
                    this.lstLoadCamAI.includes(id)
                  );
                  if (allExist) {
                    $(`#ipgr_${grp.id}`).prop("checked", true);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              this._aiService.getCamByBunch(grp.id).subscribe(
                (res2) => {
                  let lstIDRes = res2.map((cam) => cam.id);
                  this.lstLoadCamAI.forEach((id) => {
                    $("#cam_" + id).prop("checked", true);
                  });
                  let allExist = lstIDRes.every((id) =>
                    this.lstLoadCamAI.includes(id)
                  );
                  if (allExist) {
                    $(`#ipgr_${grp.id}`).prop("checked", true);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getScenarioById(id: number) {
    this._aiService.getScenarioById(id).subscribe(
      (res) => {
        this.scenario = res;
        this.exeMultiAPI();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  bunchClick(element) {
    var idGroup = element.currentTarget.id;
    let isView = $("#app_" + idGroup).css("visibility");
    if (isView == "collapse") $("#app_" + idGroup).css("visibility", "visible");
    else $("#app_" + idGroup).css("visibility", "collapse");
    // this.lstLoadCamAI.forEach((id) => {
    //   $("#cam_" + id).prop("checked", true);
    // });
  }

  changeSelectAll(event: any) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    let lstInput = $("input[name='cb_RegisAI']");
    for (let i = 0; i < lstInput.length; i++) {
      $("." + lstInput[i].className).prop("checked", isChecked);
    }
  }
  saveListCameraRegister() {
    let lstChecked = $("#tblRegisCam input:checked");
    let lstIdCamChecked = [];
    for (let i = 0; i < lstChecked.length; i++) {
      if (lstChecked[i].id.toString().includes("cam_")) {
        lstIdCamChecked.push(Number(lstChecked[i].id.toString().substring(4)));
      }
    }
    let lstAddId = [];
    let lstRemoveId = [];
    lstIdCamChecked.forEach((cc) => {
      if (Array.from(this.lstLoadCamAI).includes(cc) == false)
        lstAddId.push(cc);
    });
    this.lstLoadCamAI.forEach((c) => {
      if (Array.from(lstIdCamChecked).includes(c) == false) lstRemoveId.push(c);
    });
    if (lstAddId.length === 0 && lstRemoveId.length === 0) {
      Swal.fire(
        "Thông báo",
        "Danh sách chọn không thay đổi, vui lòng chọn camera cần đăng ký!",
        "info"
      );
    } else {
      if (lstAddId.length > 0) {
        this._aiService.registerCameraAI(this.scenario.id, lstAddId).subscribe(
          (res) => {
            Swal.fire(
              "Đăng ký camera",
              "Đăng ký camera thành công!",
              "success"
            );
          },
          (err) => {
            Swal.fire("Lỗi", "Đăng ký camera thất bại!", "error");
          }
        );
      }
      if (lstRemoveId.length > 0) {
        this._aiService
          .unRegisterCameraAI(this.scenario.id, lstRemoveId)
          .subscribe(
            (res) => {
              Swal.fire(
                "Hủy đăng ký",
                "Hủy đăng ký camera thành công!",
                "success"
              );
            },
            (err) => {
              Swal.fire("Hủy đăng ký", "Hủy đăng ký camera thất bại!", "error");
            }
          );
      }
    }
  }

  onChangePageSize(event: any) {}

  changeAssignGroup(event: any, idGroup: any) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    $(".gr_" + idGroup).prop("checked", isChecked);
  }
}
export class GroupCamera {
  id: number = 0;
  name: string = "Chưa phân nhóm";
  dateCreated: number = 0;
  accountId: number = 0;
  username: string = "";
  parentId: number = 0;
}
