<div class="sidebar">
  <a href="javascript:;" class="sidebar-toggle"></a>
  <h3 class="sidebar-title">AI CAMERA</h3>
  <div class="sidebar-content">
    <div class="list-item">
      <a data-toggle="collapse" href="#registerGroup" aria-expanded="false" aria-controls="registerGroup">
        <i class="icon icon-setting"></i>
        Quản lý đăng ký
      </a>
    </div>
    <div class="collapse show" id="registerGroup">
      <div id="camera-accordion">
        <div class="list-item" [ngClass]="{'active': isPageActive('register-scenarios')}">
          <a routerLink="/aicamera/register-scenarios">
            Đăng ký camera
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('register-face-ids')}">
          <a routerLink="/aicamera/register-face-ids">
            Đăng ký FaceID
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('register-areas')}">
          <a routerLink="/aicamera/register-areas">
            Đăng ký vùng cảnh báo
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('register-counting')}">
          <a routerLink="/aicamera/register-counting">
            Đăng ký ảnh đếm người trong khung hình
          </a>
        </div>
      </div>
    </div>
    <div class="list-item" [ngClass]="{'active': isPageActive('logs')}">
      <a routerLink="/aicamera/logs">
        <i class="icon icon-clock-circle-history"></i>
        Quản lý log
      </a>
    </div>

    <div class="list-item">
      <a data-toggle="collapse" href="#eventGroup" aria-expanded="false" aria-controls="eventGroup">
        <i class="icon icon-manage-event"></i>
        Quản lý sự kiện
      </a>
    </div>
    <div class="collapse show" id="eventGroup">
      <div id="camera-accordion">
        <div class="list-item" [ngClass]="{'active': isPageActive('check-in-events')}" *ngIf="isPageEnable('EMPLOYEE_TIMEKEEPING')">
          <a routerLink="/aicamera/check-in-events">
            Chấm công nhân viên
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('elite-guest-events')}" *ngIf="isPageEnable('VIP_WARNING')">
          <a routerLink="/aicamera/elite-guest-events">
            Cảnh báo khách hàng VIP
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('intrusion-events')}" *ngIf="isPageEnable('INTRUSION_WARNING')">
          <a routerLink="/aicamera/intrusion-events">
            Phát hiện xâm nhập
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('counting-events')}" *ngIf="isPageEnable('PEOPLE_COUNTING')">
          <a routerLink="/aicamera/counting-events">
            Đếm người trong khung hình
          </a>
        </div>
      </div>
    </div>

    <div class="list-item">
      <a data-toggle="collapse" href="#alertGroup" aria-expanded="false" aria-controls="alertGroup">
        <i class="icon icon-alert-bell"></i>
        Cảnh báo
      </a>
    </div>
    <div class="collapse show" id="alertGroup">
      <div id="camera-accordion">
        <div class="list-item" [ngClass]="{'active': isPageActive('smart-alerts')}">
          <a routerLink="/aicamera/smart-alerts">
            Danh sách cảnh báo
          </a>
        </div>
        <div class="list-item" [ngClass]="{'active': isPageActive('config-alerts')}">
          <a routerLink="/aicamera/config-alerts">
            Cấu hình cảnh báo
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
