<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-notification">
        <div class="container">
            <div class="page-action">
                <h2 class="page-title">Nạp tiền</h2>
            </div>
            <div class="page-form">
                <div class="row">
                    <div class="col col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                        <form class="payment-form" [formGroup]="paymentForm"
                            (ngSubmit)="paymentForm.valid && PaymentByBank()">
                            <div class="form-group">
                                <div class="amount-of-money-group">
                                    <label class="control-label" for="amount-of-money">Số tiền nạp</label>
                                    <input type="text" appAllowDecimalNumber class="form-control" id="amount-of-money"
                                        placeholder="Nhập số tiền" [(ngModel)]="payment.totalAmount"
                                        formControlName="amount">
                                    <span>VNĐ</span>
                                </div>
                                <div *ngIf="paymentForm.controls.amount.invalid && paymentForm.controls.amount.touched"
                                    class="alert alert-danger">
                                    {{_paymentValidateService.PaymentValidate.amountValidate.message(paymentForm.controls.amount)}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label" for="bank-name">Ngân hàng</label>
                                <div class="w-100">
                                    <select class="custom-select" id="bank-name" formControlName="bankCode" [(ngModel)]="payment.bankCode">
                                        <option value="" selected>Chọn ngân hàng</option>
                                        <option value="VISA">Thanh toán bằng thẻ Visa/Master Card</option>
                                        <option value="VCB">Ngân hàng TMCP Ngoại Thương Việt Nam (Vietcombank)</option>
                                        <option value="DAB">Ngân hàng TMCP Đông Á (DongA Bank)</option>
                                        <option value="TCB">Ngân hàng TMCP Kỹ Thương (Techcombank)</option>
                                        <option value="MB">Ngân hàng TMCP Quân Đội (MB)</option>
                                        <option value="VIB">Ngân hàng TMCP Quốc tế (VIB)</option>
                                        <option value="ICB">Ngân hàng TMCP Công Thương (VietinBank)</option>
                                        <option value="EXB">Ngân hàng TMCP Xuất Nhập Khẩu (Eximbank)</option>
                                        <option value="ACB">Ngân hàng TMCP Á Châu (ACB)</option>
                                        <option value="HDB">Ngân hàng TMCP Phát Triển Nhà TP. Hồ Chí Minh (HDBank)
                                        </option>
                                        <option value="MSB">Ngân hàng TMCP Hàng Hải (MariTimeBank)</option>
                                        <option value="NVB">Ngân hàng TMCP Nam Việt (NaviBank)</option>
                                        <option value="VAB">Ngân hàng TMCP Việt Á (VietA Bank)</option>
                                        <option value="VPB">Ngân hàng TMCP Việt Nam Thịnh Vượng  (VPBank)</option>
                                        <option value="SCB">Ngân hàng TMCP Sài Gòn Thương Tính (Sacombank)</option>
                                        <option value="GPB">Ngân hàng TMCP Dầu Khí (GPBank)</option>
                                        <option value="AGB">Ngân hàng Nông nghiệp và Phát triển Nông thôn (Agribank)
                                        </option>
                                        <option value="BIDV">Ngân hàng Đầu tư và Phát triển Việt Nam (BIDV)</option>
                                        <option value="OJB">Ngân hàng TMCP Đại Dương (OceanBank)</option>
                                        <option value="PGB">Ngân Hàng TMCP Xăng Dầu Petrolimex (PGBank)</option>
                                        <option value="SHB">Ngân hàng TMCP Sài Gòn - Hà Nội (SHB)</option>
                                        <option value="TPB">Ngân hàng TMCP Tiên Phong (TienPhong Bank)</option>
                                        <option value="NAB">Ngân hàng Nam Á</option>
                                        <option value="SGB">Ngân hàng Sài Gòn Công Thương</option>
                                    </select>
                                </div>
                                <div *ngIf="paymentForm.controls.bankCode.invalid && paymentForm.controls.bankCode.touched"
                                    class="alert alert-danger">
                                    {{_paymentValidateService.PaymentValidate.bankValidate.message(paymentForm.controls.bankCode)}}
                                </div>
                            </div>
                            <div class="form-action">
                                <button class="btn btn-primary" type="submit" [disabled]="paymentForm.invalid" style="background-color: var(--primary); border-color: var(--primary);">Xác
                                    nhận</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="idc-footer">
        <p class="copyright">Copyright © 2015 - 2025, Công ty TNHH Viettel - CHT</p>
    </footer>
</div>