<div class="container-fluid p-2">
  <div class="page-title">
    <span style="color: #b3b3b3">Quản lý sự kiện /</span> Chấm công nhân viên
  </div>
  <div style="padding: 0 1.5rem 1.5rem">
    <div style="height: 1px; width: 100%; background-color: #f3f1f1"></div>
  </div>

  <div
    class="col-md-12"
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    "
  >
    <div
      class="col-md-3"
      style="
        display: flex;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 20px 16px;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          width: 162px;
          align-items: center;
          gap: 16px;
          flex-shrink: 0;
        "
      >
        <div
          style="display: flex; align-items: center; gap: 16px; flex-shrink: 0"
        >
          <div style="width: 24px; height: 24px; flex-shrink: 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.96836 23.7753H23.7746V21.0003L21.6184 16.9315L20.3434 17.6065L22.3309 21.3565V22.3315H19.1434L19.8934 13.3315H3.88086L4.96836 23.7753ZM18.3371 14.7753L17.6996 22.3315H6.26211L5.47461 14.7753H18.3371Z"
                fill="#59B459"
              />
              <path
                d="M17.0246 7.1251C17.0246 4.3501 14.7746 2.1001 11.9996 2.1001C9.22461 2.1001 6.97461 4.3501 6.97461 7.1251C6.97461 9.9001 9.22461 12.1501 11.9996 12.1501C14.7746 12.1501 17.0246 9.9001 17.0246 7.1251ZM8.39961 7.1251C8.39961 5.1376 10.0121 3.5251 11.9996 3.5251C13.9871 3.5251 15.5996 5.1376 15.5996 7.1251C15.5996 9.1126 13.9871 10.7251 11.9996 10.7251C10.0121 10.7251 8.39961 9.1126 8.39961 7.1251Z"
                fill="#59B459"
              />
              <path
                d="M3.6373 17.6252L2.3623 16.9502L0.206055 21.0002V23.7752H3.3748V22.3502H1.6498V21.3752L3.6373 17.6252Z"
                fill="#59B459"
              />
              <path
                d="M11.8125 19.125C12.2267 19.125 12.5625 18.7892 12.5625 18.375C12.5625 17.9608 12.2267 17.625 11.8125 17.625C11.3983 17.625 11.0625 17.9608 11.0625 18.375C11.0625 18.7892 11.3983 19.125 11.8125 19.125Z"
                fill="#59B459"
              />
            </svg>
          </div>

          <div
            style="
              color: #000;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            "
          >
            Đi làm
          </div>
          <div
            style="
              color: #000;
              text-align: right;
              flex-grow: 1;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            "
          >
            {{ stWork }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-3"
      style="
        margin-left: 20px;
        display: flex;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 20px 16px;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          width: 162px;
          align-items: center;
          gap: 16px;
          flex-shrink: 0;
        "
      >
        <div
          style="display: flex; align-items: center; gap: 16px; flex-shrink: 0"
        >
          <div style="width: 24px; height: 24px; flex-shrink: 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="21"
              viewBox="0 0 25 21"
              fill="none"
            >
              <path
                d="M16.3335 3.8002C17.3235 3.8002 18.1335 2.9952 18.1335 2.0002C18.1335 1.0052 17.3235 0.200195 16.3335 0.200195C15.3385 0.200195 14.5335 1.0052 14.5335 2.0002C14.5335 2.9952 15.3385 3.8002 16.3335 3.8002ZM19.3335 11.0002C16.5735 11.0002 14.3335 13.2402 14.3335 16.0002C14.3335 18.7602 16.5735 21.0002 19.3335 21.0002C22.0935 21.0002 24.3335 18.7602 24.3335 16.0002C24.3335 13.2402 22.0935 11.0002 19.3335 11.0002ZM19.3335 19.5002C17.3985 19.5002 15.8335 17.9352 15.8335 16.0002C15.8335 14.0652 17.3985 12.5002 19.3335 12.5002C21.2685 12.5002 22.8335 14.0652 22.8335 16.0002C22.8335 17.9352 21.2685 19.5002 19.3335 19.5002ZM15.1335 9.0002H19.3335V7.2002H16.1335L14.1985 3.9352C13.9035 3.4352 13.3585 3.1002 12.7335 3.1002C12.2635 3.1002 11.8385 3.2902 11.5335 3.6002L7.8335 7.2952C7.5235 7.6052 7.3335 8.0302 7.3335 8.5002C7.3335 9.1302 7.6685 9.6602 8.1835 9.9702L11.5335 12.0002V17.0002H13.3335V10.5202L11.0835 8.8502L13.4035 6.5202L15.1335 9.0002ZM5.3335 11.0002C2.5735 11.0002 0.333496 13.2402 0.333496 16.0002C0.333496 18.7602 2.5735 21.0002 5.3335 21.0002C8.0935 21.0002 10.3335 18.7602 10.3335 16.0002C10.3335 13.2402 8.0935 11.0002 5.3335 11.0002ZM5.3335 19.5002C3.3985 19.5002 1.8335 17.9352 1.8335 16.0002C1.8335 14.0652 3.3985 12.5002 5.3335 12.5002C7.2685 12.5002 8.8335 14.0652 8.8335 16.0002C8.8335 17.9352 7.2685 19.5002 5.3335 19.5002Z"
                fill="#EE9B3E"
              />
            </svg>
          </div>

          <div
            style="
              color: #000;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            "
          >
            Chưa chấm công
          </div>
          <div
            style="
              color: #000;
              text-align: right;
              flex-grow: 1;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            "
          >
            {{ stNoKeep }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-3"
      style="
        margin-left: 20px;
        display: flex;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 20px 16px;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          width: 162px;
          align-items: center;
          gap: 16px;
          flex-shrink: 0;
        "
      >
        <div
          style="display: flex; align-items: center; gap: 16px; flex-shrink: 0"
        >
          <div style="width: 24px; height: 24px; flex-shrink: 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M19.049 5.52182L19.3119 4.51784H20.4593C20.6745 4.51784 20.8418 4.35051 20.8418 4.13537V2.46206C20.8418 2.24692 20.6745 2.07959 20.4593 2.07959H4.84976C4.63462 2.07959 4.46729 2.24692 4.46729 2.46206V4.13537C4.46729 4.35051 4.63462 4.51784 4.84976 4.51784H5.99717L6.26011 5.52182C6.92944 8.29473 8.9135 10.4939 11.5908 11.474V12.3824C8.93741 13.3625 6.95334 15.5617 6.26011 18.3346L5.92545 19.5776H4.84976C4.63462 19.5776 4.46729 19.7449 4.46729 19.9601V21.6334C4.46729 21.8485 4.63462 22.0158 4.84976 22.0158H20.4593C20.6745 22.0158 20.8418 21.8485 20.8418 21.6334V19.9601C20.8418 19.7449 20.6745 19.5776 20.4593 19.5776H19.3836L19.0729 18.3107C18.3557 15.5617 16.3717 13.3625 13.7183 12.3824V11.474C16.3717 10.4939 18.3557 8.29473 19.049 5.52182ZM5.23223 2.84453H20.0529V3.72899H5.23223V2.84453ZM20.0768 21.2509H5.25613V20.3664H20.0768V21.2509ZM13.2402 12.98C15.798 13.8166 17.7103 15.8963 18.3557 18.5019L18.6187 19.5776H6.66649L6.92944 18.5019C7.59876 15.8963 9.51111 13.8166 12.045 12.98C12.1884 12.9322 12.284 12.7888 12.284 12.6453V11.235C12.284 11.0915 12.1884 10.9481 12.045 10.9003C9.51111 10.0158 7.59876 7.96007 6.95334 5.35449L6.7382 4.51784H18.5709L18.3557 5.35449C17.7103 7.96007 15.798 10.0158 13.2402 10.8764C13.0968 10.9242 13.0011 11.0676 13.0011 11.2111V12.6214C13.0011 12.7888 13.0968 12.9322 13.2402 12.98Z"
                fill="#EE0033"
              />
            </svg>
          </div>

          <div
            style="
              color: #000;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            "
          >
            Đi muộn
          </div>
          <div
            style="
              color: #000;
              text-align: right;
              flex-grow: 1;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            "
          >
            {{ stLate }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-3"
      style="
        margin-left: 20px;
        display: flex;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 20px 16px;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          width: 162px;
          align-items: center;
          gap: 16px;
          flex-shrink: 0;
        "
      >
        <div
          style="display: flex; align-items: center; gap: 16px; flex-shrink: 0"
        >
          <div style="width: 24px; height: 24px; flex-shrink: 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.3825 5.52182L18.6454 4.51784H19.7928C20.008 4.51784 20.1753 4.35051 20.1753 4.13537V2.46206C20.1753 2.24692 20.008 2.07959 19.7928 2.07959H4.18325C3.96811 2.07959 3.80078 2.24692 3.80078 2.46206V4.13537C3.80078 4.35051 3.96811 4.51784 4.18325 4.51784H5.33066L5.59361 5.52182C6.26293 8.29473 8.247 10.4939 10.9243 11.474V12.3824C8.2709 13.3625 6.28684 15.5617 5.59361 18.3346L5.25895 19.5776H4.18325C3.96811 19.5776 3.80078 19.7449 3.80078 19.9601V21.6334C3.80078 21.8485 3.96811 22.0158 4.18325 22.0158H19.7928C20.008 22.0158 20.1753 21.8485 20.1753 21.6334V19.9601C20.1753 19.7449 20.008 19.5776 19.7928 19.5776H18.7171L18.4064 18.3107C17.6892 15.5617 15.7052 13.3625 13.0518 12.3824V11.474C15.7052 10.4939 17.6892 8.29473 18.3825 5.52182ZM4.56572 2.84453H19.3864V3.72899H4.56572V2.84453ZM19.4103 21.2509H4.58963V20.3664H19.4103V21.2509ZM12.5737 12.98C15.1315 13.8166 17.0438 15.8963 17.6892 18.5019L17.9522 19.5776H5.99998L6.26293 18.5019C6.93226 15.8963 8.84461 13.8166 11.3785 12.98C11.5219 12.9322 11.6175 12.7888 11.6175 12.6453V11.235C11.6175 11.0915 11.5219 10.9481 11.3785 10.9003C8.84461 10.0158 6.93226 7.96007 6.28684 5.35449L6.0717 4.51784H17.9044L17.6892 5.35449C17.0438 7.96007 15.1315 10.0158 12.5737 10.8764C12.4303 10.9242 12.3346 11.0676 12.3346 11.2111V12.6214C12.3346 12.7888 12.4303 12.9322 12.5737 12.98Z"
                fill="#EE0033"
              />
            </svg>
          </div>

          <div
            style="
              color: #000;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            "
          >
            Về sớm
          </div>
          <div
            style="
              color: #000;
              text-align: right;
              flex-grow: 1;
              font-family: Sarabun;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            "
          >
            {{ stEarly }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      text-align: right;
      margin-top: 10px;
      font-size: 16px;
      font-style: italic;
      line-height: normal;
    "
  >
    *Dữ liệu chấm công trong ngày {{ currDate | date : "dd/MM/y" }}
  </div>

  <div class="page-action">
    <div class="row">
      <div class="col-12">
        <div class="d-flex">
          <div class="search-form event-search mr-3">
            <label for="search-camera" class="sr-only">Tìm kiếm</label>
            <input
              type="text"
              class="form-control border-control"
              id="search-camera"
              placeholder="Nhập họ và tên"
              [(ngModel)]="txtSearch"
            />
          </div>
          <select
            style="
              display: flex;
              border-radius: 10px 10px 10px 0px;
              border: 1px solid #f6f6f6;
              height: 44px;
              padding: 10px 16px;
              align-items: center;
              gap: 32px;
              cursor: pointer;
            "
            (change)="changeEventType($event.target.value)"
          >
            <option
              style="
                color: #000;
                font-family: Sarabun;
                font-size: 16px;
                font-style: normal;
                font-weight: 250;
                line-height: normal;
              "
              value="1"
            >
              Tất cả sự kiện
            </option>
            <option
              style="
                color: #000;
                font-family: Sarabun;
                font-size: 16px;
                font-style: normal;
                font-weight: 250;
                line-height: normal;
              "
              value="2"
            >
              Checkin/Checkout
            </option>
            <option
              style="
                color: #000;
                font-family: Sarabun;
                font-size: 16px;
                font-style: normal;
                font-weight: 250;
                line-height: normal;
              "
              value="3"
            >
              Đi muộn/về sớm
            </option>
          </select>
          <!-- <div style="display: flex;border-radius: 10px 10px 10px 0px; border: 1px solid #F6F6F6; height: 44px; padding: 10px 16px; align-items: center; gap: 32px;">
            <div style="color: #000; font-family: Sarabun;font-size: 16px;font-style: normal; font-weight: 250; line-height: normal;">
              Tất cả sự kiện</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
              <path d="M5 8.25L0.669873 0.749999L9.33013 0.75L5 8.25Z" fill="#D9D9D9" />
            </svg>
          </div> -->

          <!-- <div class="filter-dropdown camera-filter mr-3">
            <div class="btn-group dropdown">
              <button class="btn dropdown-toggle border-control" type="button" data-toggle="dropdown" disabled
                aria-expanded="false">
                <span class="title" style="font-size: unset;">{{titleSelect}}</span>
              </button>
              <ul class="dropdown-menu" role="menu" [(ngModel)]="eventType">
                <li *ngFor="let type of listEventType"><a href="javascript:;">{{type.label}}</a></li>
              </ul>
            </div>
          </div> -->
          <div
            class="datetime-range-picker-toggle mr-3"
            style="cursor: pointer"
            data-toggle="modal"
            data-target="#selectDate"
          >
            {{ startTime | date : "HH:mm dd/MM/y" }} -
            {{ endTime | date : "HH:mm dd/MM/y" }}
          </div>
          <div
            style="
              display: flex;
              border-radius: 10px 10px 10px 0px;
              background: var(--light-primary-50, #e03);
              position: absolute;
              right: 0;
              width: 182px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              cursor: pointer;
            "
            (click)="exportExcel()"
          >
            <div
              style="
                color: var(--home-white-0, #fff);
                font-family: Sarabun;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              "
            >
              Xuất báo cáo
            </div>
          </div>

          <!-- <button style="border-radius: 10px 10px 10px 0px;
          background: var(--light-primary-50, #E03);
          " (click)="exportExcel()">
            <i class="icon-excel"></i> <span>Xuất báo cáo</span>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="page-table">
    <div class="container">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr
              class="header-table"
              style="padding: 15px 0px 15px 0px; text-align: center"
            >
              <th scope="col">STT</th>
              <th scope="col">Họ và Tên</th>
              <th scope="col">Mã nhân viên</th>
              <th scope="col">Vị trí cam checkin</th>
              <th scope="col">Checkin/Checkout</th>
              <th scope="col">Đi muộn/về sớm</th>
              <th scope="col">Thời gian</th>
              <th scope="col">Ảnh chụp</th>
            </tr>
          </thead>
          <!-- <tbody *ngIf="loading">
            <tr>
              <td colspan="7" class="text-center">
                <i class="fa fa-spinner fa-pulse"></i>
                <span>Đang tải dữ liệu...</span>
              </td>
            </tr>
          </tbody> -->
          <tbody
            *ngIf="currEventType === '1'"
            style="padding: 15px 0px 15px 0px"
          >
            <tr
              *ngFor="
                let event of evtFaceIds.data
                  | paginate
                    : {
                        itemsPerPage: pageSize || 10,
                        currentPage: currpagination,
                        totalItems: totalItems
                      };
                let index = index
              "
            >
              <td class="text-center">
                {{ index + 1 + pageSize * currentPage }}
              </td>
              <td class="text-center">{{ event.userName }}</td>
              <td class="text-center">{{ event.employeeCode }}</td>
              <td class="text-center">{{ event.cameraName }}</td>
              <td class="text-center">
                <img
                  src="{{ event.imageResult }}"
                  style="height: 69px; width: 60px"
                  alt=""
                />
              </td>
              <td class="text-center">-</td>
              <td class="text-center">{{ displayTime(event.timeStart) }}</td>
              <td class="text-center">
                <img
                  src="{{ event.imageUrl }}"
                  style="height: 69px; width: 60px"
                  alt=""
                />
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="currEventType === '2' || currEventType === '3'">
            <tr
              *ngFor="
                let event of lstEvtFaceModel
                  | paginate
                    : {
                        itemsPerPage: pageSize || 10,
                        currentPage: currpagination,
                        totalItems: totalItems
                      };
                let index = index
              "
            >
              <td class="text-center">
                {{ index + 1 + pageSize * currentPage }}
              </td>
              <td class="text-center">{{ event.userName }}</td>
              <td class="text-center" style="text-align: center">
                {{ event.employeeCode }}
              </td>
              <td class="text-center">{{ event.cameraName }}</td>
              <td class="text-center">{{ event.typeCheck }}</td>
              <td class="text-center">{{ event.lateOrSoon }}</td>
              <td class="text-center">
                {{ event.time | date : "dd/MM/y  HH:mm:ss" }}
              </td>
              <td class="text-center">
                <img
                  src="{{ getImageUrl(event.imageResult) }}"
                  style="height: 69px; width: 60px"
                  alt=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row no-gutters">
        <div class="col-6 text-left" style="display: flex; align-items: center">
          <label>Số hàng hiển thị mỗi trang</label>
          <select
            class="border-control"
            [(ngModel)]="pageSize"
            style="padding: 0.375rem 1rem; margin-left: 0.5rem; cursor: pointer"
            (change)="pageSizeChange()"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
        </div>
        <div class="col-6 text-right">
          <div class="text-right">
            <nav class="nav-pagination">
              <pagination-template
                #p="paginationApi"
                (pageChange)="onPageChange($event)"
              >
                <ul *ngIf="pageSize <= p.getTotalItems()" class="pagination">
                  <li class="page-item" [class.disabled]="p.isFirstPage()">
                    <a
                      *ngIf="!p.isFirstPage()"
                      class="page-link"
                      (click)="p.previous()"
                    >
                      <i class="icon icon-arrow-left"></i
                    ></a>
                  </li>

                  <li
                    class="page-item"
                    [class.active]="p.getCurrent() === page.value"
                    *ngFor="let page of p.pages"
                  >
                    <a class="page-link" (click)="p.setCurrent(page.value)">{{
                      page.label
                    }}</a>
                  </li>
                  <li class="page-item" [class.disabled]="p.isLastPage()">
                    <a
                      *ngIf="!p.isLastPage()"
                      class="page-link"
                      (click)="p.next()"
                    >
                      <i class="icon icon-arrow-right"></i
                    ></a>
                  </li>
                </ul>
              </pagination-template>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="selectDate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="selectDateTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered select-date-modal"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title" id="selectDateTitle">Chọn thời gian</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon icon-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="selection-wrapper">
                <i class="icon icon-calendar"></i>
                <div class="form-group">
                  <input
                    class="form-control"
                    id="select-date-first"
                    placeholder="Nhập ngày bắt đầu"
                  />
                </div>
              </div>
              <div class="selection-wrapper">
                <i class="icon icon-clock"></i>
                <div class="form-group">
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-hours-first"
                    >
                      <option
                        *ngFor="let h of hours; let i = index"
                        [value]="h"
                        [selected]="i == 0"
                      >
                        {{ h }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-minutes-first"
                    >
                      <option
                        *ngFor="let m of minutes; let i = index"
                        [value]="m"
                        [selected]="i == 0"
                      >
                        {{ m }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-ampm-first"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="selection-wrapper">
                <i class="icon icon-calendar"></i>
                <div class="form-group">
                  <input
                    class="form-control"
                    id="select-date-second"
                    placeholder="Nhập ngày kết thúc"
                  />
                </div>
              </div>
              <div class="selection-wrapper">
                <i class="icon icon-clock"></i>
                <div class="form-group">
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-hours-second"
                    >
                      <option
                        *ngFor="let h of hours; let i = index"
                        [value]="h"
                        [selected]="i == 0"
                      >
                        {{ h }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-minutes-second"
                    >
                      <option
                        *ngFor="let m of minutes; let i = index"
                        [value]="m"
                        [selected]="i == 0"
                      >
                        {{ m }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-ampm-second"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <input id="date-time-picker2" />
              <div id="date-time-picker-container2"></div>
            </div>
            <div class="col-12 form-action text-center mt-0">
              <button
                class="btn btn-outline-secondary mr-3"
                type="button"
                data-dismiss="modal"
              >
                Hủy bỏ
              </button>
              <button
                class="btn btn-primary"
                type="button"
                (click)="findRecord()"
              >
                Chọn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
