<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="page-container page-camera-group">
    <div class="container">
      <div class="page-action">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="search-form camera-search">
                  <label for="search-camera-group" class="sr-only">{{'ad_search'|translate}}</label>
                  <input type="text" class="form-control" id="search-camera-group" placeholder="{{'ad_search'|translate}}"
                    (keyup)="getChangesSearch($event)">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="camera-buttons">
              <button class="btn btn-primary btn-icon-text add-camera-group" data-toggle="modal"
                data-target="#addCameraGroup" (click)="getAllCamNoBunch()">
                <i class="icon icon-plus"></i> <span>{{'ad_add_bunch'|translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-table">
        <div class="row no-gutters">
          <div class="col-12 col-sm-6">
            <div class="camera-result">
              {{'li_have'|translate}} <span class="count">{{countAll}}</span> {{'ad_bunch_inlist'|translate}}
            </div>
          </div>
          <div class="col-12 col-sm-6 text-right">

            <select [disabled]="disableChoosePageSize" [(ngModel)]="pageSize"  (change)="getPageSize($event)" style="padding: 0.375rem 1rem;" class="border-control">
              <option value="5">{{'ad_view'|translate}} 5</option>
              <option value="10">{{'ad_view'|translate}} 10</option>
              <option value="20">{{'ad_view'|translate}} 20</option>
              <option value="50">{{'ad_view'|translate}} 50</option>
              <option value="100">{{'ad_view'|translate}} 100</option>
              <option value="1000">{{'ad_view'|translate}} 1000</option>
            </select>
          </div>
        </div>

        <div class="container">
<!--          <app-bunch-tables-action-group [searchKey]="term" [reload]="reloadTable" [listBunch]="listBunchPerPage" [pageSize]="pageSize" [length]="lengthPagination" (idRemove)="getRemoveId($event)" (idEditBunch)="getEditModal($event)"></app-bunch-tables-action-group>-->
          <app-bunch-tables-action-group [disableClickBunch]="disableClickBunch" [reload]="reloadTable" [listBunch]="listBunchPerPage" (idRemove)="getRemoveId($event)" (idEditBunch)="getEditModal($event)"></app-bunch-tables-action-group>
        </div>
        <div class="text-center" style="overflow-x: auto;">
          <nav class="nav-pagination">
            <app-pagination [length]="lengthPagination" [limit]="pageSize" (clickItem)="onChangePage($event)"></app-pagination>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <footer class="idc-footer">
    <p class="copyright">{{'ldp_copyright'|translate}}</p>
  </footer>
</div>


<!-- Modal -->
<div class="modal fade" id="addCameraGroup" tabindex="-1" role="dialog" aria-labelledby="addCameraGroupTitle"
  aria-hidden="true">
  <input id="ipModalGroup" value="{{group?.id}}" hidden>
  <div class="modal-dialog modal-dialog-centered camera-group-modal" role="document" data-target="#modal">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="addCameraGroupTitle">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="addGroupForm" (ngSubmit)="addGroupForm.valid && createGroup()">
          <div class="form-group">
            <label class="control-label" for="cam-name">{{'ad_name_bunch'|translate}} camera (<span class="text-danger">*</span>)</label>
            <input type="text" class="form-control" id="cam-name" placeholder="{{'li_enter_info'|translate}}" [(ngModel)]="group.name"
              formControlName="groupname">
            <div *ngIf="addGroupForm.controls.groupname.invalid && addGroupForm.controls.groupname.touched"
              class="alert alert-danger">
              {{_groupService.groupCamValidate.nameValidate.message(addGroupForm.controls.groupname)}}
            </div>
          </div>
            <div class="form-group">
              <label class="control-label">{{'ad_bunch_inlist'|translate}}</label>
                 <app-bunchtree [bunchUpdate]="bunchUpdate"  (eventSelected)="getIdSelected($event)" [reload]="reload"></app-bunchtree>
            </div>

          <div class="form-group">
            <label class="control-label">{{'ad_choose_parent'|translate}}</label>
            <div id="select-camera-group" class="transfer-camera"></div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button" (click)=" exitModal()" data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" type="submit" [disabled]="addGroupForm.invalid">{{submit}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
