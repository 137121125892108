<div class="container-fluid p-2">
  <div class="page-title">
    <span style="color: #b3b3b3">Quản lý sự kiện /</span> Đếm người trong khung
    hình
  </div>
  <div class="page-table">
    <div class="container">
      <div style="width: 100%; height: 400px">
        <div class="row">
          <div class="col-3" style="padding: 1rem 1rem 1rem 0rem">
            <select class="form-control select2" multiple #selectCamera>
              <option *ngFor="let camera of listCamera" [value]="camera.cameraId">
                {{ camera.cameraName }}
              </option>
            </select>
          </div>
          <div class="col-3 p-3">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div
                  class="datetime-range-picker-toggle mr-3"
                  style="
                    cursor: pointer;
                    border-radius: unset;
                    border-color: #999797;"
                  data-toggle="modal"
                  data-target="#selectDate"
                >
                  {{ startTime | date : "HH:mm dd/MM/y" }} -
                  {{ endTime | date : "HH:mm dd/MM/y" }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 p-3"></div>
          <div class="col-3 p-3" style="text-align: right">
            <a class="btn btn-primary"  routerLink="/aicamera/report-counting-events">Xuất báo cáo</a>
          </div>
        </div>
        <div style="font-weight: bold; text-align: center; margin-bottom: 2rem">
          Tổng số lượng người đếm theo khung giờ
        </div>
        <canvas
          #smoothLineChart
          style="width: 100%; height: 100%; border: 1px solid #999797"
        ></canvas>
        <div #chartLegend></div>
      </div>
    </div>
  </div>
  <div class="page-table" style="margin-top: 10rem">
    <div class="container">
      <div style="font-size: 16px; padding: 1rem 0 1rem 0">
        Thống kê số lượng đếm người trong khung hình camera
        <span class="title-component">{{ currCameraName }}</span>
      </div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr
              class="header-table"
              style="padding: 15px 0px; text-align: center"
            >
              <th scope="col">STT</th>
              <th scope="col">Thời gian</th>
              <th scope="col">Số lượng người trong khung hình</th>
            </tr>
          </thead>
          <tbody style="padding: 15px 0px">
            <tr
              *ngFor="
                let event of logCountings
                  | paginate
                    : {
                        itemsPerPage: pageSize || 10,
                        currentPage: currentPage,
                        totalItems: totalItems
                      };
                let index = index
              "
            >
              <td class="text-center">
                {{ index + 1 + pageSize * (currentPage - 1) }}
              </td>
              <td class="text-center">{{ displayTime(event.timestamp) }}</td>
              <td class="text-center">{{ event.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row no-gutters">
        <div class="col-6 text-left" style="display: flex; align-items: center">
          <label>Số hàng hiển thị mỗi trang</label>
          <select
            class="border-control"
            [(ngModel)]="pageSize"
            style="padding: 0.375rem 1rem; margin-left: 0.5rem; cursor: pointer"
            (change)="onLimitChange()"
          >
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
        </div>
        <div class="col-6 text-right">
          <div class="text-right">
            <nav class="nav-pagination">
              <pagination-template
                #p="paginationApi"
                (pageChange)="onPageChange($event)"
              >
                <ul *ngIf="pageSize <= p.getTotalItems()" class="pagination">
                  <li class="page-item" [class.disabled]="p.isFirstPage()">
                    <a
                      *ngIf="!p.isFirstPage()"
                      class="page-link"
                      (click)="p.previous()"
                    >
                      <i class="icon icon-arrow-left"></i
                    ></a>
                  </li>

                  <li
                    class="page-item"
                    [class.active]="p.getCurrent() === page.value"
                    *ngFor="let page of p.pages"
                  >
                    <a class="page-link" (click)="p.setCurrent(page.value)">{{
                      page.label
                    }}</a>
                  </li>

                  <li class="page-item" [class.disabled]="p.isLastPage()">
                    <a
                      *ngIf="!p.isLastPage()"
                      class="page-link"
                      (click)="p.next()"
                    >
                      <i class="icon icon-arrow-right"></i
                    ></a>
                  </li>
                </ul>
              </pagination-template>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="selectDate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="selectDateTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered select-date-modal"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title" id="selectDateTitle">Chọn thời gian</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon icon-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="selection-wrapper">
                <i class="icon icon-calendar"></i>
                <div class="form-group">
                  <input
                    class="form-control"
                    id="select-date-first"
                    placeholder="Nhập ngày bắt đầu"
                  />
                </div>
              </div>
              <div class="selection-wrapper">
                <i class="icon icon-clock"></i>
                <div class="form-group">
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-hours-first"
                    >
                      <option
                        *ngFor="let h of hours; let i = index"
                        [value]="h"
                        [selected]="i == 0"
                      >
                        {{ h }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-minutes-first"
                    >
                      <option
                        *ngFor="let m of minutes; let i = index"
                        [value]="m"
                        [selected]="i == 0"
                      >
                        {{ m }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-ampm-first"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="selection-wrapper">
                <i class="icon icon-calendar"></i>
                <div class="form-group">
                  <input
                    class="form-control"
                    id="select-date-second"
                    placeholder="Nhập ngày kết thúc"
                  />
                </div>
              </div>
              <div class="selection-wrapper">
                <i class="icon icon-clock"></i>
                <div class="form-group">
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-hours-second"
                    >
                      <option
                        *ngFor="let h of hours; let i = index"
                        [value]="h"
                        [selected]="i == 0"
                      >
                        {{ h }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-minutes-second"
                    >
                      <option
                        *ngFor="let m of minutes; let i = index"
                        [value]="m"
                        [selected]="i == 0"
                      >
                        {{ m }}
                      </option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select
                      class="select-time d-inline-block"
                      id="select-ampm-second"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <input id="date-time-picker5" hidden />
              <div id="date-time-picker-container5"></div>
            </div>
            <div class="col-12 form-action text-center mt-0">
              <button
                class="btn btn-outline-secondary mr-3"
                type="button"
                data-dismiss="modal"
              >
                Hủy bỏ
              </button>
              <button
                class="btn btn-primary"
                type="button"
                (click)="drawChartFromTimeRange()"
              >
                Chọn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
