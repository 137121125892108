import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { CameraCoungtingModel } from "src/app/_models/ai-camera";
import { AiCameraCountingService } from "src/app/_services/ai-camera-counting.service";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from "sweetalert2";

@Component({
  selector: "app-register-counting",
  templateUrl: "./register-counting.component.html",
  styleUrls: ["./register-counting.component.css"],
})
export class RegisterCountingComponent implements OnInit {
  constructor(private _countingService: AiCameraCountingService) {}
  pageSizes = [5, 10, 20, 50, 100, 1000];
  pageSize = 10;
  currentPage: number = 1;
  totalPage: number = 0;
  totalItem: number = 0;
  searchTxt: string = '';
    searchTxt$ = new Subject<string>();
  loading: boolean = false;
  cameraCoungtings: CameraCoungtingModel[] = [];

  ngOnInit(): void {
    this.searchTxt$.pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe((text) => {
        this.getAllCameraCounting();
      })
    this.getAllCameraCounting();
  }
  getAllCameraCounting() {
    this._countingService.getAllCameraAI(41, this.currentPage-1, this.pageSize, this.searchTxt).subscribe(
      (res) => {
        this.cameraCoungtings = res.data;
        this.pageSize = res.limit;
        this.totalPage = res.totalPages;
        // this.currentPage = res.page;
        this.totalItem = res.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  } 
   onPageChange(event: any) {
    this.currentPage = event;
    this.getAllCameraCounting();
  }
  onLimitChange() {
    this.currentPage = 1;
    this.getAllCameraCounting();
  }
  onSearchChange(value: string): void {
    this.currentPage = 1;
    this.searchTxt$.next(value);
  }
  showInfo(){
    Swal.fire("Thông báo", "Tính năng này hiện đang trong quá trình phát triển. Vui lòng quay lại sau!","info");
  }
}

