<div class="container-fluid p-0" id="aicamera">
  <app-header></app-header>
  <div class="page-container page-view-online">
    <div class="row w-100">
      <div class="col-12 col-md-2 col-lg-2 sidebar-wrapper">
        <aicamera-sidebar></aicamera-sidebar>
      </div>
      <div class="col-12 col-md-10 col-lg-10 content-wrapper">
        <div class="w-100 content-page">
          <router-outlet></router-outlet>
        </div>
        <div>
          <footer class="idc-footer">
            <p class="copyright">Copyright © 2015 - 2025, Công ty TNHH Viettel - CHT</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</div>
