import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, Response, RequestOptions, URLSearchParams } from '@angular/http';
import { EncryptPasswordService } from './encrypt-password.service';
import { ApiUrlService } from './api-url.service';
import { BaseService } from './base.service';
import { AccountModel } from '../_models/account.model';
import { UserModel } from '../_models/user.model';
import { CookieService } from 'ngx-cookie-service';
import { MessagingService } from './messaging.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BaseService {
  currentUser: UserModel = new UserModel();
  constructor(
    _http: Http,
    _apiUrlService: ApiUrlService,
    private _encryptPasswordService: EncryptPasswordService,
    private _messagingService: MessagingService,
    private _router: Router,
    public cookie: CookieService) {
    super(_http, _apiUrlService, cookie);
    if (this.isLogined()) {
      this.currentUser.username = JSON.parse(this.cookie.get('currentUser')).username;
    }
  }

  login(username: string, password: string, deviceId: string) {
    return this._http.post(this._apiUrlService.ACCOUNT_LOGIN,
      {
        username: username,
        password: password,
        deviceId: deviceId
      })
      .map(result => {
        this.currentUser.username = username;
        this._messagingService.subscribeTopic();
        return this.extractData(result);
      })
      .catch(this.handleError);
  }

  logout() {
    const refreshToken = JSON.parse(this.cookie.get('currentUser')).refreshToken;
    const params: URLSearchParams = new URLSearchParams();
    params.set('refresh-token', refreshToken);
    this._http.get(this._apiUrlService.ACCOUNT_LOGOUT, { search: params })
      .map(this.extractData)
      .catch(this.handleError)
      .subscribe(
        res => {
          this.cookie.set("twofa","");
          this.cookie.set("currentUser","");
          this._messagingService.unsubscribeTopic();
        },
        error=>{
          this._router.navigate(['/login']);
        }
      );
    this.cookie.delete('currentUser');
    this._router.navigate(['/login']);
  }

  isLogined(): boolean {
    if (this.cookie.get('currentUser')) {
      return true;
    }
    return false;
  }

  register(_account: AccountModel) {
    _account.type = "VIETTEL_IDC";
    return this._http.post(this._apiUrlService.ACCOUNT_REGISTER, _account, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getbalance() {
    return this._http.get(this._apiUrlService.ACCOUNT_BALANCE, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
hasPluginAI(){
  return this._http.get(this._apiUrlService.AI_CHECKPLUGIN, this.authorizationOption())
  .map(this.extractData)
  .catch(this.handleError);
}
  findAll1() {
    return this._http.get(this._apiUrlService.ACCOUNT_FIND_ALL1, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }



  genKey() {
    return this._http.get(this._apiUrlService.gen_authenticator, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getqrCode(username: string, secretKey: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', username);
    params.set('secret-key', secretKey);
    return this._http.get(this._apiUrlService.gen_qrCode, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  checkOtp(_username: string,_otpcode: string, _deviceId, _password:string) {
    return this._http.post(this._apiUrlService.check_totp,{username:_username, otpCode:_otpcode, deviceId:_deviceId, password:_password}, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  DoTwoFactor(username: string, doTwoFactor: string, _otpCode:string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', username);
    params.set('do-twofactor', doTwoFactor);
    params.set('otpCode', _otpCode);
    return this._http.get(this._apiUrlService.do_twofactor, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  PassTwoFactor(username: string, passTwoFactor: string){
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', username);
    params.set('pass-twofactor', passTwoFactor);
    return this._http.get(this._apiUrlService.pass_twofactor, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);

  }
  WriteSecretKey(_username: string, _secretkey: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', _username);
    params.set('secretkey', _secretkey);
    return this._http.get(this._apiUrlService.write_secretKey, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError)
  }
  WriteQrcode(username: string, qrcode: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', username);
    params.set('qrcode', `${qrcode}`);
    return this._http.get(this._apiUrlService.write_qrcode, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError)
  }
}
