import { Component, OnInit } from "@angular/core";
import { ScenarioGroup, ScenarioModel } from 'src/app/_models/ai-camera';
import { AiCameraEventService } from 'src/app/_services/ai-camera-event.service';
declare var $: any;
@Component({
  selector: "aicamera-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  lstScenario: ScenarioModel[];

  constructor(private _aiService: AiCameraEventService) { }

  ngOnInit(): void {
    $(".sidebar-toggle").on("click", (e) => {
      e.preventDefault();
      $(".sidebar-wrapper").toggleClass("collapsed");
    });

    this._aiService.getAllScenario().subscribe(
      res => {
        this.lstScenario = res;
        this.lstScenario.push({id:3, name:'Đếm người trong khung hình',numberCamera: 3, dateCreated: '06/03/2025', dateUpdated: '08/03/2025', code: 'PEOPLE_COUNTING',ScenarioGroup: new ScenarioGroup()});
      },
      error => {
        console.log(error);
      }
    );
  }

  isPageActive(link: string): boolean {
    return window.location.pathname.includes(link);
  }

  isPageEnable(code: string): boolean {
    if (this.lstScenario) {
      return this.lstScenario.some(x => x.code === code);
    }
    return false;
  }
}
